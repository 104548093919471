import React from 'react';
import QuoteTimer from './QuoteTimer';

const QuoteDisplay = ({ selectedQuote }) => {
  if (!selectedQuote) return null;

  const logoUrl = selectedQuote.pharmaceuticalGroupLogo
    ? selectedQuote.pharmaceuticalGroupLogo.url
    : '';
  const totalPriceStr = selectedQuote.totalPriceStr;
  const expirationDate = selectedQuote.expirationDate;

  // Function to format and round the price
  const formatRoundedPrice = (priceStr) => {
    // Remove currency symbol and spaces
    let numericStr = priceStr.replace(/[₡,\s]/g, '');
    // Parse to float
    let priceNum = parseFloat(numericStr);
    // Round to nearest integer
    let roundedPrice = Math.round(priceNum);
    // Format with commas (using locale 'es-CR' for Costa Rica)
    let formattedPrice = roundedPrice.toLocaleString('en-US');
    // Add currency symbol
    return `₡${formattedPrice}`;
  };

  const formattedPrice = formatRoundedPrice(totalPriceStr);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', // Center elements horizontally
        flexWrap: 'wrap',
        margin: '0.5em 0',
        width: '100%', // Ensure full width for proper centering
      }}
    >
      <QuoteTimer expirationDate={expirationDate} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column', // Stack elements vertically
          alignItems: 'center', // Center elements horizontally
          flexWrap: 'wrap',
        }}
      >
        <img
          src={logoUrl}
          style={{
            marginBottom: '0.5em',
            height: '4em',
            borderRadius: '0.4em',
            maxWidth: '100%',
          }}
          alt=""
        />
        <h5
          style={{
            margin: 0,
            color: '#401409',
            textDecoration: 'underline',
            fontSize: '1.5em',
            textAlign: 'center',
          }}
        >
          {formattedPrice}
        </h5>
      </div>
    </div>
  );
};

export default QuoteDisplay;
