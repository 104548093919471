import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import '../../shared/CustomCard.css';

const DrugPresentationCard = ({ name, type, quantity, unit, onClick }: DrugPresentationCardProps) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!isClicked);
    onClick(parseInt(quantity));
  }

  const backgroundColor = isClicked ? 'yellow' : 'var(--unlisted-teal)';
  const textColor = isClicked ? 'black' : 'white';

  return (
    <Card style={{ width: '8rem', background: '#fdbf20', textAlign: 'center'}} className="align-items-center mx-1" onClick={handleClick}>
      <Card.Title className='mt-1' style={{width: '-webkit-fill-available', color: '#401409'}}>{type.toUpperCase()}</Card.Title>
      <Card.Body style={{fontSize: '10pt', backgroundColor: '#401409', width: '-webkit-fill-available', color: '#FFFFFF'}}>{quantity+" "+unit.toUpperCase()}</Card.Body>
    </Card>
  );
}


export default DrugPresentationCard;
