import React, { useRef, useState } from 'react';
import { Button, Overlay, Tooltip } from 'react-bootstrap';
import './CustomButton.css';
import { CSSProperties } from 'react';
import {Menu, MenuItem} from "@mui/material";

interface CustomButtonProps {
    type:  'text' | 'icon' | 'P',
    color: 'primary' | 'secondary',
    icon?: JSX.Element,
    text?: string,
    buttonStyle?: CSSProperties,
    textStyle?: CSSProperties,
    tooltip?: boolean,
    tooltipText?: string,
    tooltipPlacement?: 'right' | 'left' | 'top' | 'bottom',
    action?: () => void,
    disabled?: boolean,
    menu?: boolean,
    menuItems?: { label: string, action?: () => void }[]
}

const typePStyles = {
    display: 'block',
    background: 'orange',
    borderRadius: 20,
    padding: 4,
    marginLeft: 2,
    fontSize: 12,
    height: 25,
    width: 25,
}

/**
 * CustomButton
 * @param type 'text', 'icon' or 'P'
 * @param icon DEPRECATED
 * @param text type === 'icon' then this property is REQUIRED, typically used with a React Icons component
 * @param buttonStyle type === 'text'
 * @param textStyle General button styling
 * @param tooltip type === 'text' -> General text styling
 * @param action On press action, if menu is true then you should not use an action
 * @param tooltipText tooltip === true -> Tooltip text
 * @param tooltipPlacement Tooltip position when shown
 * @param disabled Disables button
 * @param menu Show menu
 * @param menuItems Menu items, each item can have an action
 * @returns {JSX.Element}
 * @constructor
 */

const CustomButton = ({ type, icon, text, buttonStyle, textStyle, tooltip, action, tooltipText, tooltipPlacement, disabled, menu, menuItems }: CustomButtonProps) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const target = useRef(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                id="basic-button"
                disabled={disabled}
                ref={target}
                className='primary-button'
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                style={buttonStyle}
                onClick={tooltip ? () => setShowTooltip(!showTooltip) : (menu ? (evt) => handleClick(evt) : action)}>
                { type === 'icon' && icon ?
                    icon : <span style={type === 'P' ? typePStyles : textStyle}>{type === 'P' ? 'P' : text}</span>}
            </Button>
            { tooltip && <Overlay target={target.current} show={showTooltip} placement={tooltipPlacement}>
                {(props) => (
                    <Tooltip {...props}>
                        {tooltipText}
                    </Tooltip>
                )}
            </Overlay> }
            { menu && <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {
                    menuItems && menuItems.map((item, index) =>
                        <MenuItem
                            key={index}
                            onClick={item.action ? () => { item.action(); handleClose() } : handleClose}>
                            {item.label}
                        </MenuItem>)
                }
            </Menu> }
        </>
    )
}

export default CustomButton;
