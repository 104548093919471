import { CURRENT_BACKEND } from "../config/BackendConfiguration";
const { Utilities } = require("../../../utility/Utilities");
export class PharmacyService{
    constructor(){
        this.backendConfiguration = CURRENT_BACKEND;
        this.util = new Utilities();
    }
    findPharmaciesByLocation(latitude,longitude){       
        let lat = latitude!==null ? latitude : '9.932340161049126';
        let long = longitude!==null ? longitude : '-84.08931651229211';  
        return fetch(this.backendConfiguration.baseUrl+"/encounter/pharmacy?locationLongitude="+long+"&locationLatitude="+lat, this.util.buildFetchOptions("GET",null,true)).then(data => data.json());
    }
    getPharmaciesByGroup(data){
        var pharmaciesByGroup = {};
        data.forEach(pharmacy => {
            let pharmaceuticalGroupId = pharmacy["pharmaceuticalGroupId"];
            if(pharmaciesByGroup.hasOwnProperty(pharmaceuticalGroupId)){
                pharmaciesByGroup[pharmaceuticalGroupId].push(pharmacy["id"]);
            }else{
                pharmaciesByGroup[pharmaceuticalGroupId] = [pharmacy["id"]];
            }
        });
        return pharmaciesByGroup;
    }
    getAllPharmacies(){
        var myHeaders = new Headers();
        myHeaders.append("x-api-key", "8840511d-3094-4571-8a75-fa2c8b06a265");        
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
            credentials: 'include'
        };        
        return fetch(this.backendConfiguration.baseUrl.replace("/drsbee","/pharmacy-drug-manager")+"/manager/pharmacy/inscribe/getAll", requestOptions)
        .then(response => response.json());
    }
    getStoreQuotes(orderItems, pharmaciesByGroup){
        let drugRequests = [];
        orderItems.forEach(item => drugRequests.push({drugId: item.id, amount: item.orderAmount, vademecumId: "2"}));
        var myHeaders = new Headers();
        myHeaders.append("x-api-key", "8840511d-3094-4571-8a75-fa2c8b06a265");   
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({pharmacyIdsByGroupId: pharmaciesByGroup, drugRequests: drugRequests}),
            redirect: 'follow',
            credentials: 'include'
          };          
        return fetch(this.backendConfiguration.baseUrl.replace("/drsbee","/pharmacy-drug-manager")+"/manager/prescription/drugAvailabilityPrescription", requestOptions).then(response => response.json());
    }
}