import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import CustomButton from '../../shared/CustomButton';
import { FaPlus } from 'react-icons/fa';
import { Swal } from 'sweetalert2';

const DrugQuantitySelector = ({ units, onClick }) => {
  const [showInput, setShowInput] = useState(true);
  const [amount, setAmount] = useState(1);

  const headerColors = showInput ? ['#f7f7f7', '#000000'] : ['#5ca2a0', '#FFFFFF'];

  const handleClick = () => {
    setShowInput(!showInput);
  };

  const handleFocus = (event) => {
    event.target.select(); // Selects the text to easily overwrite the current value, but keeps the value unless the user starts typing or deletes it.
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    setAmount(inputValue);
  };

  const handleAdd = () => {
    if (amount > 0) {
      onClick(amount);
    } else {
      Swal.fire({
        title: 'Error de datos',
        text: 'Debes especificar una cantidad positiva',
        icon: 'warning',
        confirmButtonText: 'Corregir'
      }).then((result) => {
        // Handle the result if needed
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleAdd();
    }
  };

  return (
    <>
      <Card style={{ width: '15em', backgroundColor: '#401409' }}>
        <Card.Header style={{ textAlign: 'center', backgroundColor: '#fdbf20' }}>
          <h5 style={{ fontSize: '12pt' }}>O puede indicar cantidad de unidades sueltas</h5>
        </Card.Header>
        {showInput && (
          <Card.Body style={{ backgroundColor: '#401409', color: '#FFFFFF' }}>
            <div className="d-flex flex-row justify-content-between">
              <input
                type="number"
                id="quantity"
                value={amount}
                onChange={handleChange}
                onFocus={handleFocus}
                onKeyDown={handleKeyDown} // Added onKeyDown handler
                style={{ width: '50%', marginRight: '1em', borderRadius: '0.5em' }}
              />
              <span style={{ marginRight: '1em', marginTop: '0.3em' }}>
                <b>{units}</b>
              </span>
              <CustomButton
                type="icon"
                color="primary"
                disabled={amount <= 0}
                action={() => handleAdd()}
                icon={<FaPlus color="var(--black)" style={{ marginBottom: 4, height: 11 }} />}
              />
            </div>
          </Card.Body>
        )}
      </Card>
    </>
  );
};

export default DrugQuantitySelector;
