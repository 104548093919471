import react, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAuth } from "../../context/AuthContext";
const StorePreRouter = () => {
    const { authCookie, setAuthCookie } = useAuth();
    const navigate = useNavigate();
    const loggedInPatient = useSelector((state) => state.store.loggedInPatient);
    useEffect(() => {
        if (authCookie !== null && loggedInPatient !== null) {
            navigate("/store/search");
        }else if(authCookie === null || loggedInPatient === null){
            navigate("/store/search");
        }else{
            navigate("/store/login");
        }
    }, [authCookie, loggedInPatient, navigate]);
    return (
        <></>
    );
}
export default StorePreRouter;