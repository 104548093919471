export class BackendConfiguration {
    constructor(Description, baseUrl, IsProduction, IsWebView, DefaultLongitude, DefaultLatitude) {
        this.Description = Description;
        this.baseUrl = baseUrl;
        this.hostname = (new URL(this.baseUrl)).hostname;
        this.IsProduction = IsProduction;
        this.IsWebView = IsWebView;
        this.DefaultLongitude = DefaultLongitude;
        this.DefaultLatitude = DefaultLatitude;
    }
    BackendConfiguration() {
        this.RetryCount = 2;
    }
}
BackendConfiguration.LOCAL_CR_PROXY = new BackendConfiguration("Costa Rica", "http://localhost:1337/drsbee/rest", true, false, -84.0468221, 9.919188);
BackendConfiguration.DEV_CR_PROXY = new BackendConfiguration("Costa Rica", "https://dev.beeresponsive.drsbee.com:1337/drsbee/rest", true, -84.0468221, 9.919188);
BackendConfiguration.PROD_CR_PROXY = new BackendConfiguration("Costa Rica", "https://shop.drsbee.com:1337/drsbee/rest", true, false, -84.0468221, 9.919188);
BackendConfiguration.STAGING_CR_PROXY = new BackendConfiguration("Costa Rica", "https://52.167.229.123:1337/drsbee/rest", true, false, -84.0468221, 9.919188);

export const CURRENT_BACKEND = BackendConfiguration.PROD_CR_PROXY;
