import { CURRENT_BACKEND } from "../services/drsbee/config/BackendConfiguration";
import Swal from 'sweetalert2';
export class Utilities{
    constructor(){}
    formatDate(dateString) {
        const date = new Date(dateString);
    
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
    
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
    
        return `${day}/${month}/${year}`;
    }
    getAgeFromBirthDate(birthDate){
        var dateObj = new Date(birthDate);
        var monthDiff = Date.now() - dateObj.getTime();
        var ageDiff = new Date(monthDiff);
        var year = ageDiff.getUTCFullYear();
        var age = Math.abs(year - 1970);
        return age;
    }
    buildFetchOptions(method,body=null,localStorage=false){
        var headers = new Headers();
        headers.append("Cookie",window.localStorage.getItem('authorization'));
        var options = {
            method: method,
            redirect: 'follow',            
            credentials: 'include'
        };
        if(body!=null) options["body"] = body;
        return options;
    }
    extractCookie(result){
        let cookie = "";
        let parts = result.split(' - ');
        for(let element of parts){
            if(element.length==32){
                cookie=element;
                break;
            }
        }
        return 'JSESSIONID='+cookie+'; Path=/drsbee; Domain='+CURRENT_BACKEND.hostname+'; HttpOnly;';   
    }
    closeWebView(){
        if(window.localStorage.getItem("patientUUID")!==null) window.localStorage.removeItem("patientUUID");
        window.Hive.closeWebview();
    }

    removeDuplicates(arr) {
        return [...new Set(arr)];
    } 
    
    sortQuotes(arr){
        arr.sort(function(a, b) {
            if (a.cartQuoteItems.length === b.cartQuoteItems.length) {
                return b.totalPrice - a.totalPrice;
            }
            return b.cartQuoteItems.length - a.cartQuoteItems.length;
        });
        return arr;

    }   
    capitalizeString(str) {        
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }   

    getTimeUnitDescription(quantity, code){
        let timeUnits = [ { "code": "5", "description": "Año", "seconds": 31536000, "pluralDescription": "Años" }, { "code": "2", "description": "Día", "seconds": 86400, "pluralDescription": "Días" }, { "code": "1", "description": "Hora", "seconds": 3600, "pluralDescription": "Horas" }, { "code": "4", "description": "Mes", "seconds": 18144000, "pluralDescription": "Meses" }, { "code": "3", "description": "Semana", "seconds": 604800, "pluralDescription": "Semanas" } ];
        let timeUnitDescription = null;
        timeUnits.forEach(unit => {
            if(unit.code===code){
                timeUnitDescription = quantity>1 ? unit.pluralDescription : unit.description;
            }
        });
        return timeUnitDescription;
    }
    showAlert(title, text, icon, confirmButtonText){
        Swal.fire({
            title: title,
            text: text,
            icon: icon,
            confirmButtonText: confirmButtonText
        }).then((result)=>{
            
        });
    }
    convertToAMPM(times) {
        const AMPMtimes = times.map((timeObj) => {
          const { hour, minutes } = timeObj;
          let period = 'AM';      
          if (hour >= 12) {
            period = 'PM';
          }
          const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
          const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      
          return `${formattedHour}:${formattedMinutes}${period}`;
        });
      
        return JSON.stringify(AMPMtimes);
    }
    generateHoursWithFrequency(frequency, timeUnit) {
        const minutesInHour = 60;
        const hoursInDay = 24;
        const secondsInMinute = 60;
        const result = [];
        let hour = 1;
        if(timeUnit==="2"){
            if(frequency>=1){
                let hours = hoursInDay/frequency;
                while(hour<hoursInDay){
                    result.push({hour: hour, minutes: 0})
                    hour+=hours;
                }
            }else{
                result.push({hour: 7, minutes: 0})
            }
        }else if(timeUnit==="1"){
            let hour = 1;
            if(frequency>1){
                while(hour<=hoursInDay){
                    result.push({hour: hour, minutes: 0})
                    hour+=frequency;
                }
            }else{
                result.push({hour: 7, minutes: 0})
            }
        }
        return result;
    }
    maskCreditCardNumber(cardNumber) {
        if (typeof cardNumber !== 'string' || cardNumber.length < 4) {
          throw new Error('Invalid credit card number');
        }            
        const lastFourDigits = cardNumber.slice(-4);    
        return '**** **** **** ' + lastFourDigits;              
      }
    splitFullName = (fullName) => {
        const nameParts = fullName.trim().split(/\s+/);
        let firstName = '';
        let lastName = '';
    
        if (nameParts.length >= 4) {
          firstName = nameParts.slice(0, 2).join(' ');
          lastName = nameParts.slice(2).join(' ');
        } else if (nameParts.length === 3) {
          firstName = nameParts[0];
          lastName = nameParts.slice(1).join(' ');
        } else if (nameParts.length === 2) {
          firstName = nameParts[0];
          lastName = nameParts[1];
        }
    
        return { firstName, lastName };
    }

}
