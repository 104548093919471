import { CURRENT_BACKEND } from "../config/BackendConfiguration";
export class AddressService{
    constructor(){
        this.backendConfiguration = CURRENT_BACKEND;
    }
    addAddress(address, authorization){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie",authorization);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(address),
            redirect: 'follow',
            credentials: 'include'
        };

        return fetch(this.backendConfiguration.baseUrl+"/patient/address", requestOptions)
        .then(response => response.json())
    }
    getAddresses(authorization){
        var myHeaders = new Headers();
        myHeaders.append("Cookie",authorization);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
            credentials: 'include'
        };          
        return fetch(this.backendConfiguration.baseUrl+"/patient/address?id=", requestOptions).then(response => response.json());
    }
    deleteAddress(address, authorization){
        var myHeaders = new Headers();
        myHeaders.append("Cookie", authorization);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            credentials: 'include'
            
        };
        return fetch(this.backendConfiguration.baseUrl+"/patient/address/delete?id="+address.id, requestOptions)
        .then(response => response.json());
    }
}