import React, { useRef } from 'react';
import { Container, Row, Col, FormControl, InputGroup } from 'react-bootstrap';
import { IoSearch } from 'react-icons/io5';
import { BsX } from 'react-icons/bs';
import './CustomSearchInput.css';
import { useSelector } from 'react-redux';

interface CustomSearchInputProps {
    search: (val: string) => void;
}

/**
 * CustomSearchInput
 * @param search
 * Handles search current value
 * @returns {JSX.Element}
 * @constructor
 */

const CustomSearchInput = ({ search }: CustomSearchInputProps) => {
    const inputRef = useRef();

    const cleanSearch = () => {
        // Reset search
        inputRef.current.value = '';
        search('');
    }

    return (
        <Container>
            <Row className="justify-content-center">
                <Col xs={12} md={8} lg={12}>
                    <InputGroup className='my-3 custom-input-group'>
                        <InputGroup.Text style={{backgroundColor: '#fdbf20', color: '#401409'}}>
                            <IoSearch style={{ width: 21, height: 27, backgroundColor: '#fdbf20' }} />
                        </InputGroup.Text>
                        <FormControl
                            ref={inputRef}
                            aria-label='Buscar'
                            placeholder={useSelector((state) => state.prescription.searchInputPlaceholder)}
                            onChange={(evt) => search(evt.target.value)}
                            style={{backgroundColor: '#fdbf20'}}
                        />
                        <InputGroup.Text style={{backgroundColor: '#fdbf20'}}>
                            <BsX onClick={() => cleanSearch()} style={{ width: 25, height: 25, cursor: 'pointer', backgroundColor: '#fdbf20', color: '#401409'}} />
                        </InputGroup.Text>
                    </InputGroup>
                </Col>
            </Row>
        </Container>
    )
}

export default CustomSearchInput;
