import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AuthService } from '../../services/drsbee/auth/AuthService';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { useAuth } from '../../context/AuthContext';
import { setLoggedInPatient, resetState, performLogout } from '../../reducers/StoreSlice';
import { PatientService } from '../../services/drsbee/data/PatientService';
import { NativeUtilities } from '../../utility/NativeUtilities';

const authService = new AuthService();
const patientService = new PatientService();
const nativeUtil = new NativeUtilities();

const CompleteGoogleRegisterView = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { setAuthCookie } = useAuth();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        identificationTypeCode: '',
        identification: '',
        phoneNumber: '',
        password: '',
        passwordConfirmation: '',
        termsOfUse: false,
    });
    const [isLoading, setIsLoading] = useState(false);

    const googleSignInRequest = location.state?.googleSignInRequest;

    if (!googleSignInRequest) {
        navigate('/login');
        return null;
    }

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const cleanSession = () => {
        window.localStorage.clear();
        window.sessionStorage.clear();
        dispatch(performLogout());
        dispatch(resetState());
        nativeUtil.deleteAllCookies();
        setAuthCookie(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.termsOfUse) {
            Swal.fire({
                icon: 'warning',
                title: 'Términos de Uso',
                text: 'Debe aceptar los términos de uso para continuar.',
            });
            return;
        }

        if (formData.password !== formData.passwordConfirmation) {
            Swal.fire({
                icon: 'warning',
                title: 'Contraseñas no coinciden',
                text: 'La contraseña y la confirmación de contraseña no coinciden.',
            });
            return;
        }

        // Validate required fields
        const requiredFields = [
            'identification',
            'identificationTypeCode',
            'password',
            'firstName',
            'lastName',
        ];

        for (let field of requiredFields) {
            if (!formData[field]) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Campo Requerido',
                    text: `El campo ${field} es requerido.`,
                });
                return;
            }
        }

        setIsLoading(true);

        try {
            const data = {
                identification: formData.identification,
                identificationTypeCode: formData.identificationTypeCode,
                password: formData.password,
                firstName: formData.firstName,
                lastName: formData.lastName,
                googleRequestId: googleSignInRequest.requestId,
                phoneNumber: formData.phoneNumber || '',
                secretQuestion: '',
                secretAnswer: '',
                language: 'es',
                typeUserApp: 'ReactApp',
            };

            const response = await authService.createGooglePatientUser(data);

            if (response && response.successful) {
                cleanSession();

                const patientResponse = await patientService.getPatientInfo();
                if (patientResponse) {
                    dispatch(setLoggedInPatient(patientResponse));
                    navigate('/store/search');
                } else {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Error',
                        text: 'No se pudo obtener la información del paciente.',
                    });
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Registro Fallido',
                    text: response.endUserMessage || 'Hubo un problema al registrar el usuario.',
                });
            }
        } catch (error) {
            console.error('Registration failed:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Hubo un problema al registrar el usuario.',
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container>
            <CenteredCard>
                <Title>Completa tu Registro</Title>
                <FormWrapper>
                    <StyledForm onSubmit={handleSubmit}>
                        <InputGroup>
                            <StyledInput
                                type="text"
                                placeholder="Nombre"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                required
                            />
                            <StyledInput
                                type="text"
                                placeholder="Apellido"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                            />
                            <StyledSelect
                                name="identificationTypeCode" // Corrected name attribute
                                value={formData.identificationTypeCode}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Seleccione Tipo de Identificación</option>
                                <option value="1">Cédula Nacional</option>
                                <option value="2">Cédula de Residencia</option>
                                <option value="3">Pasaporte</option>
                                {/* Add other options as needed */}
                            </StyledSelect>
                            <StyledInput
                                type="text"
                                placeholder="Identificación"
                                name="identification"
                                value={formData.identification}
                                onChange={handleChange}
                                required
                            />
                            <StyledInput
                                type="text"
                                placeholder="Teléfono"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                            />
                            <StyledInput
                                type="password"
                                placeholder="Contraseña"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                            />
                            <StyledInput
                                type="password"
                                placeholder="Confirmar Contraseña"
                                name="passwordConfirmation"
                                value={formData.passwordConfirmation}
                                onChange={handleChange}
                                required
                            />
                            <TermsCheckbox>
                                <input
                                    type="checkbox"
                                    name="termsOfUse"
                                    checked={formData.termsOfUse}
                                    onChange={handleChange}
                                />
                                <label>
                                    Acepto los <a href="/terms-of-use" target="_blank" rel="noopener noreferrer">Términos de Uso</a>
                                </label>
                            </TermsCheckbox>
                        </InputGroup>
                        <ButtonGroup>
                            <StyledButton type="submit" disabled={isLoading}>
                                {isLoading ? 'Registrando...' : 'Registrarse'}
                            </StyledButton>
                        </ButtonGroup>
                    </StyledForm>
                </FormWrapper>
            </CenteredCard>
        </Container>
    );
};

// Styled Components

const Container = styled.div`
    display: flex;
    background-color: #f5f5f5;
    flex-direction: column;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    padding: 20px;
`;

const CenteredCard = styled.div`
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Title = styled.h2`
    text-align: center;
    color: #fdbf20;
    margin-bottom: 30px;
    font-size: 24px;
`;

const FormWrapper = styled.div`
    width: 100%;
`;

const StyledForm = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
`;

const StyledInput = styled.input`
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 2px solid #fdbf20;
    border-radius: 4px;
    font-size: 16px;

    &:focus {
        border-color: #e0a800;
        outline: none;
    }
`;

const StyledSelect = styled.select`
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 2px solid #fdbf20;
    border-radius: 4px;
    font-size: 16px;

    &:focus {
        border-color: #e0a800;
        outline: none;
    }
`;

const TermsCheckbox = styled.div`
    display: flex;
    align-items: center;
    margin: 8px 0;

    input {
        margin-right: 10px;
    }

    label {
        font-size: 14px;
    }

    a {
        color: #fdbf20;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`;

const ButtonGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const StyledButton = styled.button`
    background-color: #fdbf20;
    color: #401409;
    padding: 12px 20px;
    margin-bottom: 30px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #e0a800;
    }

    &:disabled {
        background-color: #e0a800;
        cursor: not-allowed;
    }
`;

export default CompleteGoogleRegisterView;
