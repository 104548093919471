import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaMedkit, FaStoreAlt, FaMapMarkerAlt } from 'react-icons/fa';
import { Button, Card } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setPerformRequote, setQuotes } from '../../../reducers/StoreSlice';
import OrderQuote from './OrderQuote';
import { PharmacyService } from '../../../services/drsbee/data/PharmacyService';
import { ShoppingCartService } from '../../../services/drsbee/data/ShoppingCartService';
import Swal from 'sweetalert2';
import LoadingSpinner from '../../shared/LoadingSpinner';
import { useAuth } from '../../../context/AuthContext';
import { Utilities } from '../../../utility/Utilities';
import { useMediaQuery } from 'react-responsive';
import { MdLocalPharmacy } from 'react-icons/md';
const pharmacyService = new PharmacyService();
const shoppingCartService = new ShoppingCartService();
const util = new Utilities();

const QuotesList = ({ isCache }) => {
  const dispatch = useDispatch();
  const authCookie = useAuth();
  const [currentQuotes, setCurrentQuotes] = useState([]);
  const [pickupQuotes, setPickupQuotes] = useState([]);
  const [expressQuotes, setExpressQuotes] = useState([]);
  const [expressFullQuotes, setExpressFullQuotes] = useState([]);
  const [expressPartialQuotes, setExpressPartialQuotes] = useState([]);
  const [pickupFullQuotes, setPickupFullQuotes] = useState([]);
  const [pickupPartialQuotes, setPickupPartialQuotes] = useState([]);
  const orderItems = useSelector((state) => state.store.orderItems);
  const selectedAddress = useSelector((state) => state.store.selectedAddress);
  const addresses = useSelector((state) => state.store.addresses);
  const [showColorTable, setShowColorTable] = useState(false);
  const navigate = useNavigate();
  const loggedInPatient = useSelector((state) => state.store.loggedInPatient);
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
  useEffect(() => {
    if (orderItems.length === 0) {
      Swal.fire({
        title: 'Carrito vacío',
        text: 'Tu carrito de compras está vacío, agrega un producto primero para encontrar cotizaciones de farmacias cercanas',
        icon: 'warning',
        confirmButtonText: 'Agregar productos',
        confirmButtonColor: '#fdbf20',
        confirmButtonTextColor: '#401409',
      }).then((result) => {
        if (result.isConfirmed) {
          setPerformRequote(false);
          navigate("/store/search");
        }
      });
      return;
    }

    let latitude, longitude;

    if (selectedAddress !== null) {
      latitude = selectedAddress.latitude;
      longitude = selectedAddress.longitude;
    } else if (addresses.length > 0) {
      latitude = addresses[0].latitude;
      longitude = addresses[0].longitude;
    } else {
      latitude = 9.935365;
      longitude = -84.098786;
    }

    if (isCache) {
      shoppingCartService.getQuoteForCachedCart(orderItems, latitude, longitude).then(response => {
        if (response.successful && response.value !== null && response.value.length > 0) {
          setShowColorTable(true);
          dispatch(setQuotes(response.value));
          setCurrentQuotes(response.value);
        } else {
          showNoQuotesWarning();
        }
      });
    } else {
      if (authCookie && loggedInPatient !== null) {
        shoppingCartService.getCartQuotes(latitude, longitude, authCookie).then(response => {
          if (response.successful && response.value !== null && response.value.length > 0) {
            setShowColorTable(true);
            dispatch(setQuotes(response.value));
            setCurrentQuotes(response.value);
          } else {
            showNoQuotesWarning();
          }
        });
      }
    }
  }, [authCookie, orderItems]);

  useEffect(() => {
    setExpressQuotes(util.removeDuplicates(currentQuotes.filter((quote) => quote.express)));
    setExpressFullQuotes(util.removeDuplicates(currentQuotes.filter((quote) => quote.express && checkFullAvailability(quote))));
    setExpressPartialQuotes(util.removeDuplicates(currentQuotes.filter((quote) => quote.express && !checkFullAvailability(quote))));
    setPickupQuotes(util.removeDuplicates(currentQuotes.filter((quote) => quote.pickUpAtPharmacy)));
    setPickupFullQuotes(util.removeDuplicates(currentQuotes.filter((quote) => quote.pickUpAtPharmacy && checkFullAvailability(quote))));
    setPickupPartialQuotes(util.removeDuplicates(currentQuotes.filter((quote) => quote.pickUpAtPharmacy && !checkFullAvailability(quote))));
  }, [currentQuotes]);

  const showNoQuotesWarning = () => {
    Swal.fire({
      title: 'No se han encontrado cotizaciones',
      text: 'Lo sentimos, parece que no hay cotizaciones disponibles en este momento. Por favor, intenta con otro medicamento',
      icon: 'warning',
      confirmButtonText: 'Revisar orden',
      confirmButtonColor: '#fdbf20',
      confirmButtonTextColor: '#401409',   
    }).then((result) => {
      setPerformRequote(false);
      navigate("/store/cart");
    });
  };

  const checkFullAvailability = (quote) => {
    return quote.quotePresentationAvailabilityPercentage === 100;
  };

  const getAvailabilityColorCode = (quote) => {
    return checkFullAvailability(quote) ? '#7acdcb' : '#fbba5a';
  };

  return (
    <>
      <div>
        <div className='d-flex flex-row justify-content-left align-items-center flex-wrap'>
          <Link to="/store/cart" className="">
            <Button variant="primary m-3" style={{ backgroundColor: '#401409', color: '#FFFFFF' }}>Volver</Button>
          </Link>
          <FaMedkit style={{ height: 35, width: 35, color: '#401409', marginRight: '1em' }} />
          <h2 style={{ marginRight: '1em', color: '#401409', marginTop: isDesktop? '0.3em' : '0.5em' }}>Cotizaciones</h2>
          {showColorTable &&
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginLeft: isDesktop ? '1em' : '7em', marginBottom: !isDesktop ? '0.5em' : '0em', marginTop: isDesktop ? '0.3em' : '0em'}}>
              <div style={{ display: 'flex', alignItems: 'center', marginRight: '1em' }}>
                <div style={{ width: '15px', height: '15px', backgroundColor: '#7acdcb', marginRight: '0.5em' }}></div>
                <span style={{ fontSize: '0.9em' }}>Completa</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '15px', height: '15px', backgroundColor: '#fbba5a', marginRight: '0.5em' }}></div>
                <span style={{ fontSize: '0.9em' }}>Incompleta</span>
              </div>
            </div>
          }
        </div>

        <Card>
          <Card.Title style={{backgroundColor: '#37373d14'}} className="d-flex align-items-center">
            <FaMapMarkerAlt style={{ height: 35, width: 35, color: '#401409', marginLeft: '1em' }} />
            <h2 style={{ color: '#401409', marginLeft: '0.5em', marginTop: '0.25em' }}>Entrega a domicilio</h2>
          </Card.Title>
          <Card.Body>
            {expressQuotes.length > 0 ?
              <>
                {expressFullQuotes.length > 0 &&
                  <>
                    <h4 style={{ color: '#401409' }}>Cotizaciones Completas</h4>
                    <div style={{ display: 'flex', overflowX: 'scroll' }}>
                      {expressFullQuotes.map((orderQuote, index) => (
                        <OrderQuote
                          key={index}
                          quote={orderQuote}
                          colorCode={getAvailabilityColorCode(orderQuote)}
                          complete={true}
                        />
                      ))}
                    </div>
                  </>
                }
                {expressPartialQuotes.length > 0 &&
                  <>
                    <h4 style={{ color: '#401409', marginTop: '1em'}}>Cotizaciones Incompletas</h4>
                    <div style={{ display: 'flex', overflowX: 'scroll' }}>
                      {expressPartialQuotes.map((orderQuote, index) => (
                        <OrderQuote
                          key={index}
                          quote={orderQuote}
                          colorCode={getAvailabilityColorCode(orderQuote)}
                          complete={false}
                        />
                      ))}
                    </div>
                  </>
                }
                {expressFullQuotes.length === 0 && expressPartialQuotes.length === 0 &&
                  <h4 style={{ color: '#401409' }}>No hay cotizaciones con opción de envío a domicilio</h4>
                }
              </>
              :
              <div className="d-flex flex-row justify-content-left">
                {currentQuotes.length > 0 && expressQuotes.length === 0 ?
                  <h4 style={{ color: '#401409' }}>No hay cotizaciones con opción de envío a domicilio</h4>
                  :
                  <div className='d-flex align-items-center'><LoadingSpinner /><h4 style={{ color: '#401409', marginLeft: '1em', marginTop: '0.3em'}}>Cargando cotizaciones</h4></div>
                }
              </div>
            }
          </Card.Body>
        </Card>

        <Card>
          <Card.Title style={{backgroundColor: '#37373d14'}} className='d-flex align-items-center'>
            <MdLocalPharmacy style={{ height: 35, width: 35, color: '#401409', marginLeft: '1em' }} />
            <h2 style={{ color: '#401409', marginLeft: '0.5em', marginTop: '0.25em' }}>Recoger en farmacia</h2>
          </Card.Title>
          <Card.Body>
            {pickupQuotes.length > 0 ?
              <>
                {pickupFullQuotes.length > 0 &&
                  <>
                    <h3 style={{ color: '#401409' }}>Cotizaciones Completas</h3>
                    <div style={{ display: 'flex', overflowX: 'scroll' }}>
                      {pickupFullQuotes.map((orderQuote, index) => (
                        <OrderQuote
                          key={index}
                          quote={orderQuote}
                          colorCode={getAvailabilityColorCode(orderQuote)}
                          complete={true}
                        />
                      ))}
                    </div>
                  </>
                }
                {pickupPartialQuotes.length > 0 &&
                  <>
                    <h3 style={{ color: '#401409', marginTop: '1em' }}>Cotizaciones Incompletas</h3>
                    <div style={{ display: 'flex', overflowX: 'scroll' }}>
                      {pickupPartialQuotes.map((orderQuote, index) => (
                        <OrderQuote
                          key={index}
                          quote={orderQuote}
                          colorCode={getAvailabilityColorCode(orderQuote)}
                          complete={false}
                        />
                      ))}
                    </div>
                  </>
                }
                {pickupFullQuotes.length === 0 && pickupPartialQuotes.length === 0 &&
                  <h4 style={{ color: '#401409' }}>No hay cotizaciones con opción de recoger en farmacia</h4>
                }
              </>
              :
              <div className="d-flex flex-row justify-content-left">
                {currentQuotes.length > 0 && pickupQuotes.length === 0 ?
                  <h4 style={{ color: '#401409' }}>No hay cotizaciones con opción de recoger en farmacia</h4>
                  :
                  <div className='d-flex align-items-center'><LoadingSpinner /><h4 style={{ color: '#401409', marginLeft: '1em', marginTop: '0.3em'}}>Cargando cotizaciones</h4></div>
                }
              </div>
            }
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default QuotesList;
