import { CURRENT_BACKEND } from "../config/BackendConfiguration";
const { Utilities } = require("../../../utility/Utilities");
export class LocationService{
    constructor(){
        this.backendConfiguration = CURRENT_BACKEND;
        this.util = new Utilities();
    }
    getAbbreviatedAddress(latitude, longitude){
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
        return fetch(url,requestOptions).then(data => data.json());
    }
};