import { CURRENT_BACKEND } from "../config/BackendConfiguration";
import { sponsoredDrugs } from "../config/SponsoredDrugs";
import { Utilities } from "../../../utility/Utilities";
export class DrugService{
    constructor(){
        this.backendConfiguration = CURRENT_BACKEND;
        this.sponsoredDrugs = sponsoredDrugs;
        this.util = new Utilities();
    }
    findDrugsByFilterWithAvailability(drugFilter, age){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({"filter":drugFilter,"ageFilter":age,"vademecumId":"2","availabilityInPharmacyIdsByGroupId":JSON.parse(window.localStorage.getItem('pharmacyList'))});
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
            credentials: 'include'
        };
        return fetch(this.backendConfiguration.baseUrl+"/drug/availability", requestOptions).then(data => data.json());
    }
    findDrugsByName(drugFilter){
        const requestOptions = {
            method: 'GET',
            redirect: 'follow',
            credentials: 'include'
        };
        if(drugFilter.length>=3){            
            return fetch(this.backendConfiguration.baseUrl+"/drug?filter="+drugFilter+"&vademecumId=2", requestOptions).then(data => data.json());
        }
        return false;
    }
    getDosageSuggestion(drugId,age,weight){
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            credentials: 'include'
        };
        if(drugId.length>0){            
            return fetch(this.backendConfiguration.baseUrl+"/drug/dosage/suggestion?drugidlist="+drugId+"&weight="+weight+"&yearsold="+age, requestOptions)
            .then(response => response.json());
        }
        return false;
    }
    getDrugTreatmentDatetimes(patientId,beginDate,hoursMinutes,duration,durationTimeUnitCode,frequency,frequencyTimeUnitCode){
        var requestOptions = {
            method: 'GET',
            credentials: 'include',
            redirect: 'follow'
        };
        let url = this.backendConfiguration.baseUrl+"/patient/"+patientId+"/drugtreatment/sameday?begindate="+beginDate+"&consumptionhoursminutes="+hoursMinutes.join(',')+"&duration="+duration+"&durationtimeunitcode="+durationTimeUnitCode+"&frequency="+frequency+"&frequencytimeunitcode="+frequencyTimeUnitCode;  
        return fetch(url, requestOptions).then(data => data.json());
    }
    remapKeys(keys,obj){
        Object.entries(keys).forEach(([oldKey, newKey]) => {
            obj[newKey] = obj[oldKey];
            delete obj[oldKey];
        });
        return obj;
    }
    isDrugSponsored(alias){
        return Object.keys(this.sponsoredDrugs).indexOf(alias.replace(' ','_'))!==-1;
    }
    getSponsoredMedia(alias){
        return this.sponsoredDrugs[alias.replace(' ','_')];
    }
    generateDrugViewModel(drug, edit){
        var drugObj = drug.drug.drugObj;
        var administrationRoute = drugObj.administrationRouteById[Object.keys(drugObj.administrationRouteById)[0]];
        var viewModel = {
            dose: (edit || parseInt(drug.dose)!==drug.dosageSuggestion.dose) ? parseInt(drug.dose) : drug.dosageSuggestion.dose,
            doseUnitCode: drugObj.doseUnitId,
            allowsGenerics: true,
            refills: 0,
            drugDescription: drugObj.description,
            drugId: drugObj.id,
            vademecumId: drugObj.vademecumId,
            vademecumDescription: drugObj.vademecumDescription,
            duration: (edit || parseInt(drug.duration)!==drug.dosageSuggestion.duration) ? parseInt(drug.duration) : drug.dosageSuggestion.duration,
            administrationRouteDescription: administrationRoute.description,
            frequencyTimeUnitCode: (edit || drug.freqTypeCode!==drug.dosageSuggestion.frequencyTimeUnitCode) ? drug.freqTypeCode : drug.dosageSuggestion.frequencyTimeUnitCode,
            durationTimeUnitCode: (edit || drug.durationTypeCode!==drug.dosageSuggestion.durationTimeUnitCode) ? drug.durationTypeCode : drug.dosageSuggestion.durationTimeUnitCode,
            administrationRouteId: administrationRoute.id,
            frequency: (edit || parseInt(drug.frequency)!==drug.dosageSuggestion.frequency)? parseInt(drug.frequency) : drug.dosageSuggestion.frequency,
            prescriptionAbbreviatureCode: (parseInt(drug.frequency)===drug.dosageSuggestion.frequency && parseInt(drug.duration)===drug.dosageSuggestion.duration && parseInt(drug.dose)===drug.dosageSuggestion.dose && drug.freqTypeCode===drug.dosageSuggestion.frequencyTimeUnitCode && drug.durationTypeCode===drug.dosageSuggestion.durationTimeUnitCode)? drug.dosageSuggestion.prescriptionAbbreviatureCode : "O",
            hours: (edit || !(parseInt(drug.frequency)===drug.dosageSuggestion.frequency && parseInt(drug.duration)===drug.dosageSuggestion.duration && parseInt(drug.dose)===drug.dosageSuggestion.dose && drug.freqTypeCode===drug.dosageSuggestion.frequencyTimeUnitCode && drug.durationTypeCode===drug.dosageSuggestion.durationTimeUnitCode))? this.util.generateHoursWithFrequency(parseInt(drug.frequency), drug.freqTypeCode) : drug.dosageSuggestion.hours,
            drugDescriptionPhrase: drugObj.suggestedDescription
        };
        return viewModel;
    }

    searchDrugs(drugName, ageFilter, authorization = null){
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        if(authorization!==null){
            myHeaders.append("Cookie", authorization);
        }else{
            myHeaders.append("BeeResponsive",'dewq/*/743dfe3*42+3');
        }
        const raw = JSON.stringify({
            "filter": drugName,
            "agefilter": ageFilter,
            "vademecumId": "2",
            "availabilityInPharmacyIdsByGroupId": {
                "1": [
                    "1"
                ]
            }
        });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            credentials: "include",
            redirect: "follow"
        };
        return fetch(this.backendConfiguration.baseUrl+"/drug/availability/patient", requestOptions).then(response => response.json());
    }

    findDrugsAsGuest(drugName){
        var myHeaders = new Headers();
        myHeaders.append("X-API-KEY", "8840511d-3094-4571-8a75-fa2c8b06a265");        
        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'        
        };

        if(drugName.length>1){
            return fetch(this.backendConfiguration.baseUrl.replace("/drsbee","/pharmacy-drug-manager")+"/manager/vademecum/searchDrugVademecum?searchFilter="+drugName, requestOptions).then(data => data.json());
        }
        return false;
    }
    getStoreQuotes(drug, pharmacies){
        
    }
    
}