import { CURRENT_BACKEND } from "../config/BackendConfiguration";
import { sponsoredDrugs } from "../config/SponsoredDrugs";
export class EnvironmentService{
    constructor(){
        this.backendConfiguration = CURRENT_BACKEND;
        this.sponsoredDrugs = sponsoredDrugs;
    }
    getEnvironment(){
        var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        credentials: 'include'
        };
        return fetch(this.backendConfiguration.baseUrl+"/info/environment", requestOptions).then(response => response.json());
    }
}
