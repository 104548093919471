import React,{useState} from 'react';
import CustomButton from '../../components/shared/CustomButton';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
const PrescriptionDetail = () => {
    const prescriptionSummary = useSelector((state) => state.prescription.prescriptionSummary);
    const downloadPrescriptionFile = () => {
        window.location = prescriptionSummary.url;
    }
    return (
        <Container style={{background: ('--var white')}}>
            <div className="d-flex flex-row">
                <span className='subtitle align-items-center'>Detalle de prescripción</span>
            </div>
            <div className="d-flex flex-row justify-content-center">
                <img src={prescriptionSummary.url} />
            </div>
            <div className='fixed-bottom d-flex flex-row' style={{ background: 'var(--unlisted-teal )' }}>
                <CustomButton
                    type='text'
                    color='primary'
                    buttonStyle={{flex: 1, height: 44, margin: '15px 15px 15px 30px', background: 'var(--unlisted-teal )', borderWidth: 2 }}
                    textStyle={{ color: 'var(--yellow)', fontWeight: 700 }}
                    action={() => window.open('', '_blank', '').close()}
                    text='Volver' />
                <CustomButton
                    type='text'
                    color='primary'
                    buttonStyle={{ flex: 1, height: 44, margin: '15px 30px 15px 15px' }}
                    textStyle={{ color: 'var(--black)', fontWeight: 700 }}
                    action={() => downloadPrescriptionFile()}
                    text='Descargar' />
            </div>
        </Container>
    )
}
export default PrescriptionDetail;