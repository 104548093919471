import React, { useEffect } from "react";
import styled from "styled-components";
import AppLauncher from "../../components/store/cart/AppLauncher";
import { FaMapMarkerAlt, FaSearch, FaHistory, FaEye } from "react-icons/fa";
import ShoppingCartCounter from "../../components/store/cart/ShoppingCartCounter";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import OrderStatus from '../../components/store/cart/OrderStatus';  // Import the new component
import { NativeUtilities } from '../../utility/NativeUtilities';
import { ShoppingCartService } from '../../services/drsbee/data/ShoppingCartService';
const nativeUtil = new NativeUtilities();
const shoppingCartService = new ShoppingCartService();
const OrderStatusView = () => {    
    const orderItems = useSelector((state) => state.store.orderItems);
    let cachedItems = [
        {
            "id": "",
            "status": "1",
            "quantity": 1.0,
            "description": "MARTESIA 75MG",
            "relatedEntityId": 2135,
            "relatedEntityClass": "com.drsmapp.entities.vademecum.Drug",
            "vademecumid": 2,
            "cartid": null,
            "prescriptionId": null
        },
        {
            "id": null,
            "status": "1",
            "quantity": 62.0,
            "description": "MARTESIA CÁPSULA 75 mg",
            "relatedEntityId": 2137,
            "relatedEntityClass": "com.drsmapp.entities.vademecum.Drug",
            "vademecumid": 2,
            "cartid": null,
            "prescriptionId": 15626
        }
   ];
    useEffect(() => {
        shoppingCartService.getQuoteForCachedCart(cachedItems, '9.936026', '-84.092461').then(response => {
            if(response.successful && response.value !== null) {
                console.log(response.value);
            }
        });
    }, []);
    return (
    <Container>
        <IconBar>
            <AppLauncher
                onClick={() => nativeUtil.launchAppLobby()}
                style={{
                    height: 38,
                    width: 50,
                    backgroundColor: "transparent"
                }}
            />
            <Link to="/store/search">
                <FaSearch
                    style={{
                        marginLeft: '-1em',
                        height: 53,
                        width: 37,
                        color: "#401409",
                        backgroundColor: "transparent"
                    }}
                />
            </Link>
            <Link to="/store/cart/addresses">
                <FaMapMarkerAlt
                    style={{
                        height: 43,
                        width: 27,
                        color: '#401409',
                        backgroundColor: "transparent"
                    }}
                />
            </Link>
            <Link to="/store/cart" style={{marginBottom: '1.9em'}}>
                <ShoppingCartCounterContainer>                    
                    <ShoppingCartCounter itemCount={orderItems.length} selected={false} />                    
                </ShoppingCartCounterContainer>
            </Link>
            <Link to="/store/order/history">
                <FaHistory
                    style={{
                        marginLeft: '2em',
                        height: 53,
                        width: 37,
                        color: "#fdbf20",
                        backgroundColor: "transparent"
                    }}
                />
            </Link>
        </IconBar>   
        <RectangleStack>
            <CenteredRectangle>
                <h3 style={{textAlign: 'center', color: '#401409'}}>Estado de orden</h3>
                <OrderStatus />  {/* Use the new component here */}
            </CenteredRectangle>
        </RectangleStack>
    </Container>      
    );
};
const ShoppingCartCounterContainer = styled.div`
    margin-bottom: 0.5em;
    margin-right: 0.5em;
    z-index: 999;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    min-height: 100vh;
    justify-content: center;
`;

const IconBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 2em;
    padding-left: 2em;
    
    
`;

const RectangleStack = styled.div`
    flex: 1;
    width: 100%;
    
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
    padding: 0.2em;
`;

const CenteredRectangle = styled.div`
    position: absolute;
    height: 93vh;
    width: 96%;
    border-width: 2px;
    border-radius: 1em;
    border-color: #fdbf20;
    margin-bottom: 1em;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    border-style: solid;
`;
export default OrderStatusView;