// RequoteService.js

import Swal from 'sweetalert2';
import { ShoppingCartService } from './ShoppingCartService';
import {
  setQuotes,
  changeSelectedQuote,
  setPerformRequote,
  setCacheItems,
  setOrderItems,
  resetOrderPrescriptions,
} from '../../../reducers/StoreSlice';

const shoppingCartService = new ShoppingCartService();

export const RequoteService = {
  requoteOrder: async (
    {
      loggedInPatient,
      authCookie,
      cacheItems,
      orderItems,
      selectedQuote,
      selectedAddress,
      addresses,
      dispatch,
      isRequoteTriggeredByCartChange = false,
      isRequoteTriggeredByLocationChange = false,
    },
    navigate
  ) => {
    // Determine if there are items to quote
    const itemsToQuote = loggedInPatient ? orderItems : cacheItems;

    if (!itemsToQuote || itemsToQuote.length === 0) {
      return null;
    }

    // Determine latitude and longitude
    let latitude, longitude;

    if (
      selectedAddress &&
      selectedAddress.latitude !== null &&
      selectedAddress.longitude !== null
    ) {
      latitude = selectedAddress.latitude;
      longitude = selectedAddress.longitude;
    } else if (
      addresses &&
      addresses.length > 0 &&
      addresses[0].latitude !== null &&
      addresses[0].longitude !== null
    ) {
      latitude = addresses[0].latitude;
      longitude = addresses[0].longitude;
    } else {
      // Default latitude and longitude
      latitude = 9.935365;
      longitude = -84.098786;
    }

    // Show loading dialog
    Swal.fire({
      title: 'Actualizando detalles cotización',
      html: `
        <div style="display: flex; justify-content: center; align-items: center; margin-top: 20px;">
          <div class="spinner" style="border: 4px solid #f3f3f3; border-top: 4px solid #fdbf20; border-radius: 50%; width: 36px; height: 36px; animation: spin 1s linear infinite;"></div>
        </div>
        <style>
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        </style>
        <p style="margin-top: 15px;">Por favor, espere...</p>
      `,
      allowOutsideClick: false,
      showConfirmButton: false,
    });

    try {
      let response;
      if (loggedInPatient) {
        response = await shoppingCartService.getCartQuotes(
          latitude,
          longitude,
          authCookie
        );
      } else {
        response = await shoppingCartService.getQuoteForCachedCart(
          itemsToQuote,
          latitude,
          longitude
        );
      }

      if (response.successful) {
        const quotes = response.value;
        if (quotes && quotes.length > 0) {
          dispatch(setQuotes(quotes));

          // Find the best quote
          let bestQuote = selectBestQuote(quotes);

          // Determine if we need to show a warning or perform a silent requote
          const shouldWarnUser = shouldShowWarning(
            selectedQuote,
            bestQuote,
            isRequoteTriggeredByCartChange,
            isRequoteTriggeredByLocationChange
          );

          dispatch(changeSelectedQuote(bestQuote));
          Swal.close();

          if (shouldWarnUser) {
            showQuoteChangeWarning(bestQuote, selectedQuote, navigate, dispatch);
          }
          return bestQuote; // Return the new selected quote
        } else {
          clearQuotes(dispatch);
          Swal.close();
          showNoAvailableQuotesWarning();
          return null;
        }
      } else {
        clearQuotes(dispatch);
        Swal.close();
        showNoAvailableQuotesWarning();
        return null;
      }
    } catch (error) {
      console.error('An error occurred while requoting the order:', error);
      clearQuotes(dispatch);
      Swal.close();
      showNoAvailableQuotesWarning();
      return null;
    }
  },
};

const selectBestQuote = (quotes) => {
  // Find the best quote based on availability, price, and distance
  let maxAvailability = Math.max(
    ...quotes.map((quote) => quote.quotePresentationAvailabilityPercentage || 0)
  );
  let bestQuotes = quotes.filter(
    (quote) =>
      (quote.quotePresentationAvailabilityPercentage || 0) === maxAvailability
  );

  let minTotalPrice = Math.min(
    ...bestQuotes.map((quote) =>
      parseFloat(quote.totalPriceStr.replace(/[₡,\s]/g, ''))
    )
  );
  bestQuotes = bestQuotes.filter(
    (quote) =>
      parseFloat(quote.totalPriceStr.replace(/[₡,\s]/g, '')) === minTotalPrice
  );

  let minDistance = Math.min(...bestQuotes.map((quote) => quote.distanceMeters));
  bestQuotes = bestQuotes.filter((quote) => quote.distanceMeters === minDistance);

  return bestQuotes[0];
};

const shouldShowWarning = (
  selectedQuote,
  newQuote,
  isRequoteTriggeredByCartChange,
  isRequoteTriggeredByLocationChange
) => {
  if (!selectedQuote) {
    return false;
  }

  const isDifferentPharmacy = selectedQuote.pharmacyId !== newQuote.pharmacyId;
  const isDifferentGroup =
    selectedQuote.pharmaceuticalGroupId !== newQuote.pharmaceuticalGroupId;
  const priceIncreased =
    parseFloat(newQuote.totalPriceStr.replace(/[₡,\s]/g, '')) >
    parseFloat(selectedQuote.totalPriceStr.replace(/[₡,\s]/g, ''));
  const availabilityReduced =
    newQuote.quotePresentationAvailabilityPercentage <
    selectedQuote.quotePresentationAvailabilityPercentage;

  const isPickup = selectedQuote.pickUpAtPharmacy;

  // Define conditions based on your table
  if (isPickup) {
    if (isDifferentPharmacy || priceIncreased) {
      return true;
    }
    if (isRequoteTriggeredByLocationChange && isDifferentPharmacy) {
      return true;
    }
  } else {
    if (
      (isDifferentGroup && priceIncreased) ||
      availabilityReduced ||
      (isDifferentPharmacy && priceIncreased)
    ) {
      return true;
    }
    if (isRequoteTriggeredByLocationChange && priceIncreased) {
      return true;
    }
  }

  return false;
};

const showQuoteChangeWarning = (newQuote, oldQuote, navigate, dispatch) => {
  Swal.fire({
    title: 'Se ha encontrado una nueva cotización',
    html: `
      <p>La cotización ha cambiado. Por favor, revisa la nueva cotización seleccionada.</p>
    `,
    icon: 'info',
    confirmButtonText: 'Aceptar',
    confirmButtonColor: '#fdbf20',
  }).then(() => {
    // Optionally navigate to the quotes page
    navigate('/store/cart/quotes');
  });
};

const clearQuotes = (dispatch) => {
  dispatch(changeSelectedQuote(null));
  dispatch(setQuotes([]));
};

const showNoAvailableQuotesWarning = () => {
  Swal.fire({
    title: 'No hay cotizaciones disponibles',
    text: 'No hay cotizaciones disponibles para su pedido.',
    icon: 'warning',
    confirmButtonText: 'Aceptar',
    allowOutsideClick: false,
    showConfirmButton: true,
  });
};
