import React, { useState } from 'react';
import ColegioCostaRica from '../../assets/logos/colegiomedicos.png';
import { Container, Row } from "react-bootstrap";
import { useParams, Navigate } from "react-router-dom";
import { AuthService } from '../../services/drsbee/auth/AuthService';
import { PharmacyService } from '../../services/drsbee/data/PharmacyService';
import { setAuthorizationToken, setPhysician, setPhysicianBillingSettings, setPharmacyList } from '../../reducers/RxSlice';
import './Validate.css';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import { useDispatch } from 'react-redux';
import { Utilities } from '../../utility/Utilities';
const Validate = () => {
    const util = new Utilities()
    const authService = new AuthService();
    const pharmacyService = new PharmacyService();
    const [physicianUser, setPhysicianUser] = useState(JSON.parse(window.localStorage.getItem('physician')));
    const dispatch = useDispatch();
    const {hash} = useParams();
    const redirectToLogin = () => {
        return <Navigate to="/store/login" replace />;
    };
    const validateUser = (authHash) => {
        if(physicianUser==null){
            authService.loginAsPhysician(authHash).then(res => {
                if(res.successful){
                    dispatch(setAuthorizationToken(authHash));
                    authService.getLoggedInPhysician().then(resp => {
                        dispatch(setPhysician(authService.buildPhysicianVM(resp.value.physicianProfile)));
                        dispatch(setPhysicianBillingSettings(resp.value.userSettings.currencySettingByName))
                        let physician = JSON.parse(window.localStorage.getItem('physician'));
                        setPhysicianUser(physician);
                        pharmacyService.findPharmaciesByLocation(physician.doctorOffices[0].locationLatitude,physician.doctorOffices[0].locationLongitude).then(pharmacyResult => {
                            if(pharmacyResult.successful){
                                dispatch(setPharmacyList(pharmacyService.getPharmaciesByGroup(pharmacyResult.value)));
                            }
                        })
                        if(window.localStorage.getItem("patientUUID")!==null){
                            return <Navigate to={`/start/encounter/${window.localStorage.getItem("patientUUID")}/${{hash}.hash}`} replace />;    
                        }
                        return <Navigate to="/" replace />;
                    });
                    authService.checkSession().then(data => {
                        var cookie = data.value;
                        if(cookie.indexOf("NOUSER")!==-1){
                            authService.destroySession();                        
                        }else{
                          let authorization = util.extractCookie(cookie);
                          document.cookie=authorization;
                          window.localStorage.setItem('authorization',authorization);
                        }
                    });
                }
            });
        }else if(physicianUser!=null){
            authService.checkSession().then(data => {
                var cookie = data.value;
                if(cookie.indexOf("NOUSER")!==-1){
                    validateUser(authHash);
                }
            });
            if(window.localStorage.getItem("patientUUID")!==null){
                return <Navigate to={`/start/encounter/${window.localStorage.getItem("patientUUID")}/${{hash}.hash}`} replace />;    
            }
            return <Navigate to="/" replace />;
        }else{
            return <Navigate to="/unauthorized" replace />;
        }
    };
    return(<Container>
        <Row className='justify-content-center p-5'>
            {{hash}.hash!=="undefined" && {hash}!==null ? validateUser({hash}.hash) : redirectToLogin()}
            <img src={ColegioCostaRica} style={{width: '15em'}} />
            <div className="d-flex justify-content-center"><LoadingSpinner /><p className="mx-1">Validating... Please wait</p></div>
        </Row>
    </Container>);
};
export default Validate;