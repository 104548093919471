import React, {useEffect} from 'react';
import {useDispatch, useSelector } from 'react-redux'; 
import { ShoppingCartService } from '../../services/drsbee/data/ShoppingCartService';
import { AddressService } from '../../services/drsbee/data/AddressService';
import { changeSelectedAddress, changeSelectedPaymentMethod, restoreStateAction, setOrderItems, setAddresses, setEnvironment, setPaymentMethods, setLoggedInPatient } from '../../reducers/StoreSlice';
import { EnvironmentService } from '../../services/drsbee/environment/EnvironmentService';
import { PaymentService } from '../../services/drsbee/data/PaymentService';
import { useAuth } from '../../context/AuthContext';
import { NativeUtilities } from '../../utility/NativeUtilities';
const environmentService = new EnvironmentService();
const shoppingCartService = new ShoppingCartService();
const addressService = new AddressService();
const paymentService = new PaymentService();
const nativeUtil = new NativeUtilities();
const StorePreloader = () => {
    const authCookie = useAuth();
    const dispatch = useDispatch();
    const loggedInPatient = useSelector((state) => state.store.loggedInPatient);
    const addresses = useSelector((state) => state.store.addresses);
    const paymentMethods = useSelector((state) => state.store.paymentMethods);
    const orderItems = useSelector((state) => state.store.orderItems);
    const selectedAddress = useSelector((state) => state.store.selectedAddress);
    const selectedPaymentMethod = useSelector((state) => state.store.selectedPaymentMethod);
    const environment = useSelector((state) => state.store.environment);

    const evaluateCarts = (parsedState) => {
        let equalCarts = true;
        let newItems = [];
        parsedState.orderItems.forEach(cacheItem => {
            if(!orderItems.some(item => item.relatedEntityId === cacheItem.relatedEntityId)){
                newItems.push(cacheItem)
                equalCarts = false;
            }
        });
        return {"equalCarts":equalCarts, "newItems": newItems};
    };
    useEffect(() => {
        if(environment===null && window.location.pathname.indexOf("login")===-1){
            environmentService.getEnvironment().then(envResponse => {
                if(envResponse.successful && envResponse.value!==null){
                    dispatch(setEnvironment(envResponse.value));
                }
            });
        }
    },[environment,dispatch]);
    useEffect(() => {
        if(authCookie && loggedInPatient!==null && window.location.pathname.indexOf("store")!==-1){            
            shoppingCartService.getCart(authCookie).then(cartResponse => {   
                if(cartResponse.successful && cartResponse.value!==null){
                    dispatch(setOrderItems(cartResponse.value.items));
                }      
            });
            if(environment===null){
                environmentService.getEnvironment().then(envResponse => {
                    if(envResponse.successful && envResponse.value!==null){
                        dispatch(setEnvironment(envResponse.value));
                    }
                });
            }
            addressService.getAddresses(authCookie).then(addressResponse => {
                if(addressResponse.successful && addressResponse.value!=null){
                  let userAddresses = [];
                    Object.keys(addressResponse.value).forEach(key => {
                      userAddresses.push(addressResponse.value[key]);
                    });
                    dispatch(setAddresses(userAddresses))
                  }
            });   
            paymentService.getPaymentMethods(authCookie).then(pmResponse => {
                if(pmResponse.successful && pmResponse.value!==null){
                    dispatch(setPaymentMethods(pmResponse.value.creditCards))
                }
            });  
        }
    }, [authCookie, loggedInPatient, dispatch]);

    useEffect(() => {
        if(selectedAddress===null && addresses.length>0){
          dispatch(changeSelectedAddress(addresses[0]));
        }
      },[selectedAddress,addresses,authCookie]);
    useEffect(() => {
    if(selectedPaymentMethod===null && paymentMethods.length>0){
        dispatch(changeSelectedPaymentMethod(paymentMethods[0]));
    }
    },[selectedPaymentMethod,paymentMethods,authCookie]);

    return(
        <>
        </>
    );
};
export default StorePreloader;