import React from 'react';
import {styled, TextField} from "@mui/material";

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: 'var(--unlisted-teal)',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'var(--teal)',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'var(--unlisted-teal)',
        },
        '&:hover fieldset': {
            borderColor: 'var(--unlisted-teal)',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'var(--unlisted-teal)',
        },
    },
    '& label': {
        color: 'var(--unlisted-teal)',
    },
});

interface CustomTextFieldProps {
    label: string;
    value: string;
    onChange: (value: string) => void;
}

/**
 * CustomTextField
 * @param label Text string
 * @param value Used to show current value state managed
 * @param onChange On text change functionality
 * @returns {JSX.Element}
 * @constructor
 */

const CustomTextField = ({ label, value, onChange }: CustomTextFieldProps) => {
    return(
        <CssTextField
            label={label}
            required
            variant="outlined"
            fullWidth
            value={value}
            onChange={(evt) => onChange(evt.target.value)}
        />
    )
}

export default CustomTextField;
