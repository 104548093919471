import { createSlice } from '@reduxjs/toolkit'
export const rxSlice = createSlice(
    {name: 'prescription',
    initialState: {
        user: null,
        physician: window.localStorage.getItem('physician')!==null ? JSON.parse(window.localStorage.getItem('physician')) : { id: '', identification: '', identificationTypeCode: '', identificationTypeDescription: '', firstName: '', lastName: '', nameTitle: '', physicianCode: '', specialityCode: '', specialityDescription: '', doctorOffices: [ { id: '', description: '', address: '', email: '', phone: '', locationLatitude: '', locationLongitude: '', timeZoneId: '', physician: '', sundaySchedule: '', mondaySchedule: '', tuesdaySchedule: '', wednesdaySchedule: '', thursdaySchedule: '', fridaySchedule: '', saturdaySchedule: '' } ] },
        physicianBillingSettings: null,
        authorizationToken: window.localStorage.getItem('br_token'),
        patient: window.localStorage.getItem('patient')!==null ? JSON.parse(window.localStorage.getItem('patient')) : {id: "",firstName: "", lastName: "", identification: ""},
        patientProfile: null,
        encounter: null,
        date: null,
        pharmacyList: [],
        drugList: [],
        prescriptionVM: [],
        drugListData: [],
        currentDrug: {id: "", name: "",content: "", quantity: "", image: "", available: 0,logo: ""},
        currentDispense: {dose: 0, administrationRouteId: "", prescriptionAbbreviatureCode: "", frequency: 0, frequencyTimeUnitCode: "", duration: 0, durationTimeUnitCode: "", consumptionVerb: "", doseUnitId: "", dayHours: [], patientAdvice: ""},
        lastResults: [],
        searchInputPlaceholder: "",
        prescriptionSummary: null,
    },
    reducers: {
        setDispensePatientAdvice: (state, action) => {
            state.currentDispense.patientAdvice = action.payload;
        },
        setDispenseDose: (state, action) => {
            state.currentDispense.dose = action.payload;
        },
        setDispenseAdministrationRouteId: (state, action) => {
            state.currentDispense.administrationRouteId = action.payload;
        },
        setDispenseAbbreviatureCode: (state, action) => {
            state.currentDispense.prescriptionAbbreviatureCode = action.payload;            
        },
        setDispenseFrequency: (state, action) => {
            state.currentDispense.frequency = action.payload;
        },
        setDispenseFrequencyTimeUnit: (state, action) => {
            state.currentDispense.frequencyTimeUnitCode = action.payload;
        },
        setDispenseDuration: (state, action) => {
            state.currentDispense.duration = action.payload;
        },
        setDispenseDurationTimeUnit: (state, action) => {
            state.currentDispense.durationTimeUnitCode = action.payload;
        },
        setDispenseConsumptionVerb: (state, action) => {
            state.currentDispense.consumptionVerb = action.payload;
        },
        setDispenseDoseUnitId: (state, action) => {
            state.currentDispense.doseUnitId = action.payload;
        },
        setDispenseDayHours: (state, action) => {
            state.currentDispense.dayHours = action.payload;
        },
        setAuthorizationToken: (state, action) => {
            state.authorizationToken = action.payload;
            window.localStorage.setItem('br_token',state.authorizationToken);
        },
        setPhysician: (state, action) => {
            state.physician = action.payload;
            window.localStorage.setItem('physician',JSON.stringify(state.physician));
        },
        setPhysicianBillingSettings: (state, action) => {
            state.physicianBillingSettings = action.payload;
            window.localStorage.setItem('physicianBillingSettings',JSON.stringify(state.physicianBillingSettings));
        },
        setPatient: (state, action) => {
            state.patient = action.payload;
        },
        setPatientProfile: (state, action) => {
            state.patientProfile = action.payload;
        },
        setEncounter: (state, action) => {
            state.encounter = action.payload;
        },
        setDate: (state) => {
            state.date = new Date();
        },
        setPharmacyList: (state, action) => {
            state.pharmacyList = action.payload;
            window.localStorage.setItem('pharmacyList',JSON.stringify(state.pharmacyList));
        },
        addToPrescription: (state, action) => {
            state.drugList = [...state.drugList,action.payload];
        },
        discardDrugItem: (state, action) => {
            state.drugList.splice(action.payload,1);
        },
        addDrugData: (state, action) => {
            state.drugListData = [...state.drugListData,action.payload];
        },
        setCurrentDrug: (state, action) => {
            state.currentDrug = action.payload;
        },
        setLastResults: (state, action) => {
            state.lastResults = action.payload;
        },
        setSearchPlaceholder: (state, action) => {
            state.searchInputPlaceholder = action.payload;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
        updatePrescriptionVM: (state, action) => {
            state.prescriptionVM = [...state.prescriptionVM,action.payload];
        },
        removeFromPrescriptionVM: (state, action) => {
            state.prescriptionVM.splice(action.payload,1);
        },
        setPrescriptionSummary: (state, action) => {
            state.prescriptionSummary = action.payload;
        }
    }
});
export const {setPhysician, setPhysicianBillingSettings, setPharmacyList, setAuthorizationToken, setPatient, setPatientProfile, setEncounter, setDate, addToPrescription, addDrugData, setCurrentDrug, setLastResults, setSearchPlaceholder, setUser, updatePrescriptionVM, setPrescriptionSummary, discardDrugItem, removeFromPrescriptionVM, setDispenseAbbreviatureCode, setDispenseAdministrationRouteId, setDispenseConsumptionVerb, setDispenseDayHours, setDispenseDose, setDispenseDoseUnitId, setDispenseDuration, setDispenseDurationTimeUnit, setDispenseFrequency, setDispenseFrequencyTimeUnit, setDispensePatientAdvice} = rxSlice.actions;
export default rxSlice.reducer;