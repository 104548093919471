import { CURRENT_BACKEND } from "../config/BackendConfiguration";
export class PaymentService{
    constructor(){
        this.backendConfiguration = CURRENT_BACKEND;
    }
    getPaymentMethods(authorization){
        var myHeaders = new Headers();
        myHeaders.append("Cookie", authorization);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
            credentials: 'include'
        };
        return fetch(this.backendConfiguration.baseUrl+"/payment/method", requestOptions)
        .then(response => response.json())
    }
    addPaymentMethod(cipheredCard, authorization){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie", authorization);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(cipheredCard),
            redirect: 'follow',
            credentials: 'include'
        };
        return fetch(this.backendConfiguration.baseUrl+"/payment/creditcard", requestOptions).then(response => response.json());
    }
    removePaymentMethod(paymentMethod, authorization){
        var myHeaders = new Headers();
        myHeaders.append("Cookie", authorization);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            credentials: 'include'
        };
        return fetch(this.backendConfiguration.baseUrl+"/payment/method/"+paymentMethod.id+"/remove", requestOptions)
        .then(response => response.json())
    }
    payOrder(orderId, paymentMethodId, authorization){
        var myHeaders = new Headers();
        myHeaders.append("Cookie", authorization);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            credentials: 'include'
        };
        return fetch(this.backendConfiguration.baseUrl+"/payment/order/cart/process?orderId="+orderId+"&creditCardId="+paymentMethodId, requestOptions).then(response => response.json())
    }       

    payOrderAsGuest(orderId, ccData, keyData, identificationType, identification, firstName, lastName, addressDetails, emailContact, phoneUser) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const requestBody = {
            cipheredCreditCard: {
                keyData: keyData,
                ccData: ccData
            },
            creditCardId: null,
            privateKey: null,
            orderId: orderId,
            billingInfoId: null,
            billingName: null,
            billingIdentification: null,
            billingIdentificationTypeCode: null,
            billingEmail: null,
            deliveryAddressId: null,
            deliveryAddressDetail: null,
            deliveryBuildingDetail: null,
            politicalRegionCode: "",
            userLanguage: "",
            politicalRegionCodeForExpress: null,
            expressDeliveryService: null,
            phoneUser: phoneUser,
            addressDetails: addressDetails,
            emailContact: emailContact,
            identification: identification,
            identificationType: identificationType,
            firstName: firstName,
            lastName: lastName,
            payAfter: false
        };

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(requestBody),
            redirect: "follow"
        };
        return fetch(this.backendConfiguration.baseUrl + "/payment/order/process/privatekey", requestOptions)
            .then(response => response.json());
    }    
}