// PurchaseInfo.js
import React from 'react';
import { FaMapMarkerAlt, FaRegCreditCard, FaUserAlt } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { NativeUtilities } from '../../../utility/NativeUtilities';
import QuoteDisplay from '../cart/QuoteDisplay';
import { useMediaQuery } from 'react-responsive';
import { MdLocalPharmacy } from 'react-icons/md';
import Swal from 'sweetalert2';
import { setTempItems } from '../../../reducers/StoreSlice';

const nativeUtil = new NativeUtilities();

const PurchaseInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addresses = useSelector((state) => state.store.addresses);
  const paymentMethods = useSelector((state) => state.store.paymentMethods);
  const quotes = useSelector((state) => state.store.quotes);
  const selectedAddress = useSelector((state) => state.store.selectedAddress);
  const selectedPaymentMethod = useSelector((state) => state.store.selectedPaymentMethod);
  const selectedQuote = useSelector((state) => state.store.selectedQuote);
  const patient = useSelector((state) => state.store.loggedInPatient);
  const cacheItems = useSelector((state) => state.store.cacheItems);

  const addAddressRoute =
    patient !== null ? '/store/cart/addresses' : '/store/cart/addresses/add';

  const isQuoteSelected = selectedQuote !== null;
  const isAddressProvided = selectedAddress !== null;
  const isPaymentMethodProvided = selectedPaymentMethod !== null;
  const isPatientLoggedIn = patient !== null;

  const pendingIconColor = '#808080'; // Grey for pending steps
  const completedIconColor = '#fdbf20'; // Original color for completed steps
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });

  const launchQuotesView = () => {
    if (patient !== null) {
      navigate('/store/cart/quotes');
    } else {
      launchSignIn();
    }
  };

  const launchPaymentMethodsView = () => {
    if (patient !== null) {
      navigate('/store/cart/payment_methods');
    } else {
      launchSignIn();
    }
  };

  const launchSignIn = () => {
    nativeUtil.launchNativeLogin();
  };

  const redirectToLogin = () => {
    Swal.fire({
      title: 'Inicio de sesión',
      text: 'Está seguro de que desea dirigirse a la página de inicio de sesión?',
      icon: 'question',
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#fdbf20',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (cacheItems !== null && cacheItems.length > 0) {
          dispatch(setTempItems(cacheItems));
        }
        navigate('/store/login');
      }
    });
  };

  // Define the address text to display, ensuring we don't access properties of null
  const addressText =
    selectedAddress !== null
      ? selectedAddress.addressDetail
      : addresses.length === 0
      ? patient !== null
        ? '[Agrega una dirección de entrega]'
        : '[Confirma la ubicación de entrega]'
      : '[Selecciona una dirección de entrega]';

  return (
    <>
      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td colSpan="3">
              <hr />
            </td>
          </tr>
          {/* Step 1: Select Quote */}
          <tr onClick={() => launchQuotesView()}>
            <td>
              <MdLocalPharmacy
                style={{
                  color: isQuoteSelected ? completedIconColor : pendingIconColor,
                  width: '1.6em',
                  height: '1.6em',
                  marginLeft: '1em',
                }}
              />
            </td>
            <td
              style={{
                fontWeight: 'bold',
                fontSize: isDesktop ? '12pt' : '10pt',
                width: '8em',
              }}
            >
              1. Farmacia:
            </td>
            <td style={{ fontSize: '11.5pt', fontWeight: 'bold', textAlign: 'center' }}>
              {patient !== null ? (
                <Link to="/store/cart/quotes">
                  {quotes.length === 0 || selectedQuote === null ? (
                    <span
                      style={{
                        fontSize: '11pt',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: '#401409',
                      }}
                    >
                      [Selecciona una farmacia y cotización]
                    </span>
                  ) : (
                    <QuoteDisplay selectedQuote={selectedQuote} />
                  )}
                </Link>
              ) : (
                <Link to="/store/cart/cache/quotes">
                  {quotes.length === 0 || selectedQuote === null ? (
                    <span
                      style={{
                        fontSize: '11pt',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: '#401409',
                      }}
                    >
                      [Selecciona una farmacia y cotización]
                    </span>
                  ) : (
                    <QuoteDisplay selectedQuote={selectedQuote} />
                  )}
                </Link>
              )}
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              <hr />
            </td>
          </tr>
          {/* Step 2: Provide Address */}
          <tr onClick={() => navigate(addAddressRoute)}>
            <td>
              <FaMapMarkerAlt
                style={{
                  color: isAddressProvided ? completedIconColor : pendingIconColor,
                  width: '1.6em',
                  height: '1.6em',
                  marginLeft: '1em',
                }}
              />
            </td>
            <td
              style={{
                fontWeight: 'bold',
                fontSize: isDesktop ? '12pt' : '10pt',
                width: '8em',
              }}
            >
              2. {patient !== null ? 'Dirección' : 'Ubicación'}:
            </td>
            <td
              style={{
                fontSize: '11pt',
                fontWeight: 'bold',
                textAlign: 'center',
                marginLeft: '1em',
              }}
            >
              <Link to={addAddressRoute}>
                <span style={{ color: '#401409' }}>{addressText}</span>
              </Link>
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              <hr />
            </td>
          </tr>
          {/* Step 3: Specify Payment Method */}
          <tr onClick={() => launchPaymentMethodsView()}>
            <td>
              <FaRegCreditCard
                style={{
                  color: isPaymentMethodProvided ? completedIconColor : pendingIconColor,
                  width: '1.6em',
                  height: '1.6em',
                  marginLeft: '1em',
                }}
              />
            </td>
            <td
              style={{
                fontWeight: 'bold',
                fontSize: isDesktop ? '12pt' : '10pt',
                width: '8em',
              }}
            >
              3. Pago:
            </td>
            <td
              style={{
                fontSize: '11pt',
                fontWeight: 'bold',
                textAlign: 'center',
                marginLeft: '1em',
              }}
            >
              {patient !== null ? (
                <Link to="/store/cart/payment_methods">
                  <span style={{ color: '#401409' }}>
                    {paymentMethods.length === 0 || selectedPaymentMethod === null
                      ? '[Agrega un método de pago]'
                      : selectedPaymentMethod.vendorDescription +
                        ' - ' +
                        selectedPaymentMethod.maskedNumber}
                  </span>
                </Link>
              ) : (
                <Link to="/store/cart/payment_methods/pay">
                  {selectedPaymentMethod === null ? (
                    <span
                      style={{
                        fontSize: '11pt',
                        fontWeight: 'bold',
                        textAlign: 'left',
                        color: '#401409',
                      }}
                      onClick={() => launchSignIn()}
                    >
                      [Ingresa un método de pago]
                    </span>
                  ) : (
                    <span
                      style={{
                        fontSize: '11pt',
                        fontWeight: 'bold',
                        textAlign: 'left',
                        color: '#401409',
                      }}
                    >
                      {selectedPaymentMethod.maskedNumber}
                    </span>
                  )}
                </Link>
              )}
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              <hr />
            </td>
          </tr>
          {/* Profile Section */}
          <tr>
            <td>
              <FaUserAlt
                style={{
                  color: isPatientLoggedIn ? completedIconColor : pendingIconColor,
                  width: '1.6em',
                  height: '1.6em',
                  marginLeft: '1em',
                }}
              />
            </td>
            <td
              style={{
                fontWeight: 'bold',
                fontSize: isDesktop ? '12pt' : '10pt',
                width: '8em',
              }}
            >
              Perfil:
            </td>
            <td
              style={{
                fontSize: '11pt',
                fontWeight: 'bold',
                textAlign: 'center',
                marginLeft: '1em',
                color: '#0d6efd',
              }}
            >
              {patient !== null ? (
                <span style={{ textAlign: 'left', color: '#401409' }}>
                  {patient.firstName + ' ' + patient.lastName}
                </span>
              ) : (
                <span
                  style={{ color: '#401409', textDecoration: 'underline' }}
                  onClick={() => redirectToLogin()}
                >
                  [Inicia sesión para disfrutar de una mejor experiencia de uso]
                </span>
              )}
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              <hr />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default PurchaseInfo;
