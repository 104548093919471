import React, { useEffect, useState } from 'react';
import { FaShoppingCart } from 'react-icons/fa';
import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { resetCartSpin } from '../../../reducers/StoreSlice';

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const CartIcon = styled(FaShoppingCart)`
  ${({ spinning }) => spinning && css`
    animation: ${spin} 1s;
  `}
`;

const ShoppingCartCounter = ({ itemCount, selected }) => {
  const dispatch = useDispatch();
  const spinCartIcon = useSelector((state) => state.store.spinCartIcon);
  const [spinning, setSpinning] = useState(false);

  useEffect(() => {
    if (spinCartIcon) {
      setSpinning(true);
      setTimeout(() => {
        setSpinning(false);
        dispatch(resetCartSpin()); // Reset the spin flag in Redux
      }, 1000);
    }
  }, [spinCartIcon, dispatch]);

  const iconSize = 40;
  const backgroundColor = selected ? '#fdbf20' : '#401409';
  const textColor = selected ? '#401409' : '#FFFFFF';

  return (
    <div>
      <div
        style={{
          marginBottom: '0.5em',
          position: 'absolute',
          display: 'inline-block',
        }}
      >
        <CartIcon
          id="shopping-cart-icon"
          size={iconSize}
          style={{ color: backgroundColor }}
          spinning={spinning ? 'true' : undefined} // Convert boolean to string to avoid warnings
        />
        <span
          id="item-count"
          style={{
            fontSize: '11.5pt',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontWeight: 'bold',
            color: textColor,
            padding: '3px 8px',
            borderRadius: '50%',
            marginTop: '-0.25em',
            marginLeft: '0.2em'
          }}
        >
          {itemCount}
        </span>
      </div>
    </div>
  );
};

ShoppingCartCounter.propTypes = {
  itemCount: PropTypes.number.isRequired,
  selected: PropTypes.bool,
};

export default ShoppingCartCounter;
