import React from 'react';
import { Modal } from 'react-bootstrap';
import './CustomModal.css';

interface CustomModalProps {
    title: string;
    headerContent: JSX.Element;
    bodyContent: JSX.Element;
    handleClose: () => void;
    show: boolean;
    fullBleed: boolean;
}

/**
 * CustomModal
 * @param title Modal title text
 * @param headerContent Modal header JSX element content
 * @param bodyContent Modal body content
 * @param show Show Modal
 * @param handleClose On close action
 * @param fullBleed Extends modal height
 * @returns {JSX.Element}
 * @constructor
 */

const CustomModal = ({ title, headerContent, bodyContent, show, handleClose, fullBleed }: CustomModalProps) => {

    return(
        <Modal style={{top: '0'}} className={`custom-modal ${fullBleed && 'custom-modal-fullbleed'} overflow-auto`} show={show} onHide={handleClose}>
            <Modal.Header className='custom-modal-header d-flex flex-column align-items-start' closeButton>
                <Modal.Title>
                    <span style={{ fontWeight: 700, fontSize: 18, color: '#401409' }}>{title}</span>
                </Modal.Title>
                {headerContent}
            </Modal.Header>
            <Modal.Body className='custom-modal-body'>
                {bodyContent}
            </Modal.Body>
        </Modal>
    )}

export default CustomModal;
