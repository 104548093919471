import React, {useEffect, useState} from 'react';
import { Container, Row, Form } from 'react-bootstrap';
import './MainPrescriptionView.css';
import CustomCard from '../../components/shared/CustomCard';
import ColegioCostaRica from '../../assets/logos/ColegioCostaRica.svg';
import Divider from '../../components/shared/Divider';
import CustomButton from '../../components/shared/CustomButton';
import { FaPlus} from 'react-icons/fa';
import Recipes from '../../components/erx/Recipes';
import { Link } from 'react-router-dom';
import { AuthService } from '../../services/drsbee/auth/AuthService';
import CustomSearchInput from '../../components/shared/CustomSearchInput';
import { Navigate } from 'react-router-dom';
import { PatientService } from '../../services/drsbee/data/PatientService';
import { useSelector, useDispatch } from 'react-redux';
import { setPatient, setPatientProfile, setSearchPlaceholder, setEncounter, setPrescriptionSummary } from '../../reducers/RxSlice';
import { PrescriptionService } from '../../services/drsbee/data/PrescriptionService';
import { Utilities } from '../../utility/Utilities';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import Swal from 'sweetalert2';
const authService = new AuthService();
const patientService = new PatientService();
const util = new Utilities();
const currentData = { medicines: [] }
const MainPrescriptionView = () => {
    const patient = useSelector((state) => state.prescription.patient);
    const [patientSelect, setPatientSelect] = useState(patient.firstName!=="");
    const [searchPatient, setSearchPatient] = useState(patient.firstName==="" && window.localStorage.getItem('patientUUID')===null);
    const [validSession, setValidSession] = useState(true);
    const [refreshSession, setRefreshSession] = useState(false);
    const [unregisteredPatient, setUnregisteredPatient] = useState(false);
    const [selectedIdType, setSelectedIdType] = useState("1");
    const [loading, setLoading] = useState(false);
    const identificationTypes = [{"identificationTypeCode":"1","identificationTypeDescription":"Cédula Costarricense"},{"identificationTypeCode":"2","identificationTypeDescription":"Otra"}];
    const patientProfile = useSelector((state) => state.prescription.patientProfile);
    const physician = JSON.parse(window.localStorage.getItem('physician'));
    const dispatch = useDispatch();
    const prescriptionService = new PrescriptionService();
    const encounter = useSelector((state) => state.prescription.encounter);
    const prescriptionVM = useSelector((state) => state.prescription.prescriptionVM);
    const beeToken = window.localStorage.getItem('br_token');    
    currentData.medicines = useSelector((state) => state.prescription.drugList);    
    useEffect(() => {
        if(physician!==null){
            authService.checkSession().then(data => {
                var cookie = data.value;
                if(cookie.indexOf("NOUSER")!==-1){
                    setValidSession(false);
                    if(beeToken!==null) setRefreshSession(true);
                    authService.destroySession();
                }
            });            
        }
        if(setSearchPatient) dispatch(setSearchPlaceholder("Cédula/Nombre del paciente"));
    }, [physician, dispatch, beeToken]);    
    const beginEncounter = () => {        
        setLoading(true);
        setSearchPatient(false);
        setPatientSelect(false);          
        prescriptionService.beginEncounter(patient).then(response => {
            if(response.successful){
                let encounter = response.value;
                dispatch(setEncounter(encounter));
                if(patientProfile!==null){
                    setLoading(false);
                }else{
                    let profile = patientService.getPatientProfileById(encounter.patientId);
                    if(profile!==false){
                        profile.then(resp => {
                            if(resp.successful){
                                dispatch(setPatientProfile(patientService.cleanPatientProfile(resp.value)));
                            }else{
                                dispatch(setPatientProfile(patientService.getPatientProfileFromEncounter(encounter,patient)));
                            }
                            setLoading(false);
                        })
                        .catch(err => console.log(err));
                    }
                }
            }
        });
    };
    const reviewEncounter = () => {
        if(encounter!==null){
            var encounterVM = prescriptionService.buildEncounterViewModel(encounter,patientService.cleanPatientProfile(patientProfile),prescriptionService.buildPrescriptionViewModel(prescriptionVM));
            prescriptionService.prescriptionReview(prescriptionService.buildReviewViewModel(encounterVM)).then(resp => {
                dispatch(setPrescriptionSummary(resp.value));
            });
        }else{
            alert("Debe comenzar la atención y agregar elementos a la prescripción para continuar");
        }
    }
    
    const showNoEncounterError = () => {
        Swal.fire({
            title: 'No se ha seleccionado un paciente',
            text: 'Para comenzar una atención, digite el número de identificación del paciente, y presione "Iniciar atención"',
            icon: 'error',
            confirmButtonText: 'Entendido'
        });
    }
    const showInvalidPatientError = () => {
        Swal.fire({
            title: 'Identificación inválida',
            text: 'Por favor compruebe los datos ingresados e intente nuevamente. Si no encuentra al paciente mediante la búsqueda, utilice la opción "Registrar paciente"',
            icon: 'question',
            showDenyButton: true,
            confirmButtonText: 'Entendido',
            denyButtonText: 'Registrar paciente',
            customClass: {
                actions: 'my-actions',
                confirmButton: 'order-2',
                denyButton: 'order-1 right-gap',
            }
        }).then(result => {
            if(result.isDenied) setUnregisteredPatient(true);
        });
    }
    const changeIdType = event => {
        setSelectedIdType(event.target.value);
        if(event.target.value==="2") {
            setUnregisteredPatient(true);
            dispatch(setPatientProfile(null));
        }
        else{
            setUnregisteredPatient(false);
            dispatch(setPatient({id: "",firstName: "", lastName: "", identification: ""}));
            dispatch(setPatientSelect(false))
        }
        
        
        
    };
    
    const handleSearch = (val) => {
        if(val.length>0){
            let res = patientService.findPatientById(selectedIdType,val);
            if(res!==false){
                res.then(resp => {
                    if(resp.successful && resp.value.length>0){
                        dispatch(setPatient(resp.value[0]));
                        setPatientSelect(true);
                    }else{
                        showInvalidPatientError();
                    }
                })
                .catch(err => console.log(err));
            }
        }else{
            dispatch(setPatient({id: "",firstName: "", lastName: "", identification: ""}));
            setPatientSelect(false);
        }
    };
    useEffect(() => {
        if(patient.firstName!==null && patient.firstName!==""){
            setPatientSelect(true);
            if(window.localStorage.getItem('patientUUID')!==null) setSearchPatient(false);
        }
    },[patient,patientProfile,patientSelect]);
    
    const _getCustomCardBody = () =>
    <>
        <div className='body-bg' style={{ backgroundImage: `url(${ColegioCostaRica})` }}>
            <span className='extra-bold' style={{ fontSize: 14, lineHeight: '1.5rem' }}>{physician.nameTitle + " " + physician.firstName + " " + physician.lastName}</span>
            <div className='d-flex flex-row justify-content-between'>
                <span className='extra-bold' style={{ fontSize: 14, lineHeight: '1.5rem' }}>{physician.specialityDescription}</span>
                <div className='d-flex flex-row'>
                    <span className='extra-bold' style={{ fontSize: 14, lineHeight: '1.5rem', paddingLeft: 8 }}>Código</span>
                    <span style={{ fontSize: 14, lineHeight: '1.5rem', color: 'var(--dark-green)', fontStyle: 'italic' }}>{'\u00A0'}{physician.physicianCode==null? "No registrado" : physician.physicianCode }</span>
                </div>
            </div>
            <div className='d-flex flex-row'>
                <span style={{ fontSize: 14, lineHeight: '1.5rem' }}>Centro Médico: {physician.doctorOffices[0].description} | Teléfono: {physician.doctorOffices[0].phone}</span>
            </div>
            <Divider/>
            {   (searchPatient && encounter==null) ?                  
                <div>
                    <div className='d-flex flex-row justify-content-center'><span className="extra-bold">Digite los datos del paciente</span></div>          
                    <Form.Select value={selectedIdType} onChange={changeIdType} className='custom-form-select mt-2' id="idType">                    
                        { identificationTypes.map((identificationType, index) => <option key={index} value={identificationType.identificationTypeCode}>{identificationType.identificationTypeDescription}</option>)}
                    </Form.Select>  
                    {!unregisteredPatient ? 
                        <div>                    
                            <div className='d-flex flex-row justify-content-between'>
                                <CustomSearchInput search={handleSearch} />
                            </div>
                        </div>
                        :
                        <div>
                        <div className='d-flex flex-row justify-content-center mt-2'>
                            <Link to="usr/patient/register">
                            <CustomButton
                                type='text'
                                color='primary'
                                buttonStyle={{width: '20em', height: '2.5em', background: 'var(--unlisted-teal )', borderWidth: 1 }}
                                textStyle={{ color: 'var(--white)', fontWeight: 700 }}
                                text='Registrar nuevo paciente' />
                            </Link>
                        </div></div>
                    }   
                    
                </div> : null
            }
            {loading ? <div className="d-flex flex-row align-items-center justify-content-center my-4"><LoadingSpinner /><span className="mx-1">Iniciando atención médica</span></div> : null}
            {((patientSelect || patientProfile!==null) && !unregisteredPatient)? (<div>
                <div className='d-flex flex-row justify-content-center'>
               <span className='bold' style={{ fontSize: 14, lineHeight: '1.5rem', textTransform: 'uppercase', color: 'var(--unlisted-green)' }}>PACIENTE</span>
               </div>
                <Divider/>
                <div className='d-flex flex-row justify-content-center'>
                    <span className='extra-bold' style={{ fontSize: 14, lineHeight: '1.5rem' }}>{ patientProfile!==null ? patientProfile.firstName + " " + patientProfile.lastName : patient.firstName + " " + patient.lastName}</span>
                </div>
                <div className='d-flex flex-row justify-content-between'>
                    <span style={{ fontSize: 14, lineHeight: '1.5rem' }}><b>ID:</b> {patientProfile!==null ? patientProfile.identification : patient.identification}</span>
                    <span style={{ fontSize: 14, lineHeight: '1.5rem' }}>{(util.getAgeFromBirthDate(patientProfile!==null ? util.getAgeFromBirthDate(patientProfile.birthDate) : util.getAgeFromBirthDate(patient.birthDate))!=="Nan"? util.getAgeFromBirthDate(patient.birthDate) : "")} años</span>
                </div>
                <Divider/>
                </div>) : null}
            {   (patientProfile!==null && encounter!==null) ? 
            <div className='d-flex flex-row justify-content-between'>
                <span className='extra-bold' style={{ fontSize: 14, lineHeight: '1.5rem' }}>Medicamentos</span>
                <Link to="rx/add_drug">
                <CustomButton
                    type='icon'
                    color='primary'
                    icon={<FaPlus color='var(--black)' style={{ marginBottom: 4, height: 11 }} />}                    
                />
                </Link>
            </div> : null}
        </div>
        <Recipes medicines={currentData.medicines} style={{overflowY: 'scroll' }}/>
    </>
    

    return (
        validSession ? 
        <Container style={{ paddingRight: 5, paddingLeft: 5 }}>
            

            <Row>
                <CustomCard body={_getCustomCardBody()} />
            </Row>
            <div className='fixed-bottom d-flex flex-row p-2 justify-content-between mb-3' style={{ background: 'var(--unlisted-teal )' }}>
                <CustomButton
                    type='text'
                    color='primary'
                    buttonStyle={{width: '12em', height: '2.5em', background: 'var(--unlisted-teal )', borderWidth: 2 }}
                    textStyle={{ color: 'var(--yellow)', fontWeight: 700 }}
                    action={() => util.closeWebView()}
                    text='Cancelar' />
                {encounter!==null ? 
                    <Link to={patientProfile!==null? "/rx/review" : "#"}> 
                    <CustomButton
                        type='text'
                        color='primary'
                        buttonStyle={{ width: '12em', height: '2.5em', background: patientProfile===null? 'var(--unlisted-teal)' :  null}}
                        textStyle={{ color:  patientProfile===null? 'var(--yellow)' : 'var(--black)', fontWeight: 700 }}
                        action={patientProfile!==null ? () => reviewEncounter() : null}
                        text='Recetar' />
                    </Link> 
                    : 
                    (((patientSelect || patientProfile!==null) && encounter===null)?
                        <CustomButton
                            type='text'
                            color='primary'
                            buttonStyle={{ width: '12em', height: '2.5em' }}
                            textStyle={{ color: 'var(--black)', fontWeight: 700 }}
                            action={() => beginEncounter()}
                            text='Iniciar Atención' />
                            :
                        <CustomButton
                            type='text'
                            color='primary'
                            buttonStyle={{ width: '12em', height: '2.5em', background: 'var(--unlisted-teal )'}}
                            textStyle={{ color: 'var(--yellow)', fontWeight: 700 }}
                            action={() => showNoEncounterError()}
                            text='Iniciar Atención' />                        
                    )
                }
            </div>
        </Container>
        :
        (refreshSession ? <Navigate to={`/usr/validate/${beeToken}`} replace /> : <Navigate to="/signin" replace />)         
    )
}
export default MainPrescriptionView;

