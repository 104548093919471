// PatientService.js

import { CURRENT_BACKEND } from "../config/BackendConfiguration";
import { Utilities } from "../../../utility/Utilities";

export class PatientService {
  constructor() {
    this.backendConfiguration = CURRENT_BACKEND;
    this.util = new Utilities();
  }

  // Method to find patient by identification type and number
  async findPatientById(idType, idNumber) {
    const minLength = idType === "1" ? 9 : 3;
    if (idNumber.length >= minLength) {
      const response = await fetch(
        `${this.backendConfiguration.baseUrl}/patient/search?criteria=${idNumber}&includeunregistered=true&limit=10`,
        this.util.buildFetchOptions("GET", null, true)
      );
      return this._handleResponse(response);
    }
    return false;
  }

  // Method to find patient by Hive UUID
  async findPatientByHiveUUID(uuid) {
    const response = await fetch(
      `${this.backendConfiguration.baseUrl}/info/externaluser?externalId=${uuid}`,
      this.util.buildFetchOptions("GET", null, true)
    );
    return this._handleResponse(response);
  }

  // Method to get patient profile from an encounter
  getPatientProfileFromEncounter(encounter, patient) {
    let patientProfile = {
      firstName: patient.firstName,
      lastName: patient.lastName,
      email: encounter.patientClinicalData.email,
      phoneNumber: encounter.patientClinicalData.phoneNumber,
    };
    Object.keys(encounter).forEach((key) => {
      if (key.indexOf("patient") !== -1) {
        let newKey = key.replace("patient", "");
        newKey = `${newKey[0].toLowerCase()}${newKey.substring(1)}`;
        patientProfile[newKey] = encounter[key];
      }
    });
    let {
      name,
      clinicalData,
      profile,
      registered,
      autopaymentEnabled,
      ...profileVM
    } = patientProfile;
    return profileVM;
  }

  // Method to get patient profile by ID
  async getPatientProfileById(patientId) {
    if (patientId.length > 0) {
      const response = await fetch(
        `${this.backendConfiguration.baseUrl}/patient/profile/${patientId}`,
        this.util.buildFetchOptions("GET", null, true)
      );
      return this._handleResponse(response);
    }
    return false;
  }

  // Method to clean patient profile data
  cleanPatientProfile(profile) {
    let {
      address,
      residenceCountryCode,
      residenceCountryDescription,
      profileImage,
      modifiedProfileImage,
      occupationCode,
      occupationDescription,
      ethnicityCode,
      ethnicityDescription,
      maritalStatusCode,
      maritalStatusDescription,
      insurance,
      socialSecurity,
      deathDate,
      deathDateGlobal,
      deathCertificateNumber,
      nextAppointmentDate,
      email,
      phoneNumber,
      ...cleanProfile
    } = profile;
    if (profile.email !== null) cleanProfile["email"] = profile.email;
    if (profile.phoneNumber !== null) cleanProfile["phoneNumber"] = profile.phoneNumber;
    return cleanProfile;
  }

  // Method to get patient prescription by private key
  async getPatientPrescriptionByPrivateKey(privateKey) {
    const response = await fetch(
      `${this.backendConfiguration.baseUrl}/patient/prescription/quote?privateKey=${privateKey}`,
      this.util.buildFetchOptions("GET", null, true)
    );
    return this._handleResponse(response);
  }

  // Method to get patient information
    async getPatientInfo() {
        const requestOptions = {
            method: 'GET',
            headers: {},
            redirect: 'follow',
            credentials: 'include',
        };
        const response = await fetch(`${this.backendConfiguration.baseUrl}/user/patient`, requestOptions);
        return this._handleResponse(response);
    }
  // Private method to handle API responses
    async _handleResponse(response) {
        if (response.ok) {
            try {
                return await response.json();
            } catch (jsonError) {
                return {};
            }
        } else if (response.status === 401) {
            throw new Error("Unauthorized");
        } else {
            let errorMessage = `HTTP error! Status: ${response.status}`;
            try {
                const errorJson = await response.json();
                if (errorJson && errorJson.message) {
                    errorMessage = errorJson.message;
                }
            } catch (e) {
                const errorText = await response.text();
                if (errorText) {
                    errorMessage = errorText;
                }
            }
            throw new Error(errorMessage);
        }
    }

}
