import React, {useState, useRef} from 'react';
import { Container, Form } from 'react-bootstrap';
import './AddDrugView.css';
import CustomButton from '../../components/shared/CustomButton';
import CustomModal from '../../components/shared/CustomModal';
import { useNavigate} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setEncounter, setPatientProfile } from '../../reducers/RxSlice';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import { PrescriptionService } from '../../services/drsbee/data/PrescriptionService';
import { PatientService } from '../../services/drsbee/data/PatientService';
import Swal from 'sweetalert2';
const prescriptionService = new PrescriptionService();
const patientService = new PatientService();
const currentData = {
    identificationTypes: [{"identificationTypeCode":"1","identificationTypeDescription":"Cédula Costarricense"},{"identificationTypeCode":"2","identificationTypeDescription":"Otra"}]
}
const RegisterPatient = () => {
    const [show, setShow] = useState(true);
    const [identificationTypeCode, setIdentificationTypeCode] = useState('2');
    const [loading, setLoading] = useState(false);
    const firstName = useRef('');
    const lastName = useRef('');
    const identification = useRef('');    
    const phoneNumber = useRef('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleClose = () => navigate(-1);
    const changeIdType = event => {
        setIdentificationTypeCode(event.target.value);
    };
    const showDataError = () => {
        Swal.fire({
            title: 'Ha ocurrido un error al registrar el paciente',
            text: 'Por favor compruebe que todos los datos ingresados sean correctos e inténtelo nuevamente',
            icon: 'error',
            confirmButtonText: 'Entendido'
        });
    }
    const beginEncounter = () => {
        setLoading(true);
        let birthDate = document.querySelector('#birthDate').value;
        prescriptionService.beginEncounterNewPatient(identificationTypeCode,identification.current.value,firstName.current.value,lastName.current.value,birthDate,phoneNumber.current.value).then(response => {            
            if(response.successful){
                let encounter = response.value;
                dispatch(setEncounter(encounter));
                let profile = patientService.getPatientProfileById(encounter.patientId);
                let patient = {"firstName": firstName.current.value, "lastName": lastName.current.value};
                if(profile!==false){
                    profile.then(resp => {
                        if(resp.successful){
                            dispatch(setPatientProfile(patientService.getPatientProfileFromEncounter(encounter,patient)));
                            handleClose();
                        }else{
                            showDataError();
                        }
                        setLoading(false);
                    })
                    .catch(err => console.log(err));
                }
            }
        });
    };
    const _getHeaderContent = () => (
    <div className='d-flex flex-row pt-2 pb-4 align-items-center justify-content-center mx-3 w-100'>
        <div className='d-flex flex-column' style={{ flex: 0.75 }}>
            <div className='d-flex flex-row'>
                <div className='d-flex px-2 align-items-center' style={{ flex: 0.2, fontSize: 14, marginLeft: -25 }}>
                    
                </div>
                
            </div>
        </div>
        <div className='d-flex flex-row justify-content-between align-items-center' style={{ flex: 0.25 }}>
            
        </div>
    </div>
    )
    const _getBodyContent = () => (
        <>
            <div className='d-flex flex-row align-items-center m-3 justify-content-center' style={{textAlign: 'center', fontSize: 14}}><span className="bold">Identificación del paciente</span></div>
            <div className='d-flex flex-row align-items-center m-2 justify-content-center'>
                <Form.Select value={identificationTypeCode} onChange={changeIdType} className='custom-form-select' id="idType">
                    <option disabled={true} value="">Tipo de identificación</option>
                    { currentData.identificationTypes.map((identificationType, index) => <option key={index} value={identificationType.identificationTypeCode}>{identificationType.identificationTypeDescription}</option>)}
                </Form.Select>
            </div>
            <div className='d-flex flex-row align-items-center m-2'>
                <Form.Control
                        className='custom-form-control'
                        id="identification"
                        type='text'
                        ref={identification}
                        placeholder='Identificación'
                        style={{}}
                />
            </div>

            
            <div className='d-flex flex-row align-items-center m-3 justify-content-center' style={{textAlign: 'center', fontSize: 14}}><span className="bold">Datos del paciente</span></div>                        
            <div className='d-flex flex-row align-items-center m-2'>
                <Form.Control
                    className='custom-form-control'
                    id="firstName"
                    type='text'
                    ref={firstName}
                    placeholder='Nombre'
                    style={{}}
                />
            </div>
            <div className='d-flex flex-row align-items-center m-2 mt-2'>
                <Form.Control
                    className='custom-form-control'
                    id="lastName"
                    type='text'
                    ref={lastName}
                    placeholder='Apellidos'
                    style={{ }}
                />
            </div>
            <div className='d-flex flex-row align-items-center m-2 justify-content-between mt-2'>
                <label for="birthDate"><span className='bold' style={{fontSize: 12}}>Fecha de nacimiento:</span></label>
                <input type="date" id="birthDate" name="birthDate" style={{borderRadius: '0.4em', width: '16em'}}/>
            </div>
            <div className='d-flex flex-row align-items-center m-2'>
                <Form.Control
                    className='custom-form-control'
                    id="phoneNumber"
                    type='text'
                    ref={phoneNumber}
                    placeholder='Teléfono'
                    style={{ }}
                />
            </div>
            {loading ? <div className="d-flex flex-row align-items-center justify-content-center my-3"><LoadingSpinner /><span className="mx-1" style={{fontSize: 16}}>Iniciando atención médica, por favor espere...</span></div> : null}
            
        </>
    )

    return (
        <Container style={{ paddingRight: 30, paddingLeft: 30, textAlign: "center"}} className='justify-content-center'>
            <CustomModal
                title='Registrar nuevo paciente'
                headerContent={_getHeaderContent()}
                bodyContent={_getBodyContent()}
                handleClose={handleClose}
                show={show}
                fullBleed
            />
            { show &&
                <div className='fixed-bottom d-flex flex-row' style={{ background: 'var(--unlisted-teal )', zIndex: 1056 }}>
                    <CustomButton
                        type='text'
                        color='primary'
                        buttonStyle={{flex: 1, height: 44, margin: '15px 15px 15px 30px', background: 'var(--unlisted-teal )', borderWidth: 2 }}
                        textStyle={{ color: 'var(--yellow)', fontWeight: 700 }}
                        action={() => handleClose()}
                        text='Cancelar' />
                    <CustomButton
                        type='text'
                        color='primary'
                        buttonStyle={{ flex: 1, height: 44, margin: '15px 30px 15px 15px' }}
                        textStyle={{ color: 'var(--black)', fontWeight: 700 }}
                        text="Iniciar cita"
                        action={() => beginEncounter()}
                        />
                </div>
            }
        </Container>
    )
}

export default RegisterPatient;
