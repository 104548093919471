import React, { useState } from 'react';
import ColegioCostaRica from '../../assets/logos/colegiomedicos.png';
import { Container, Row } from "react-bootstrap";
import { useParams, Navigate } from "react-router-dom";
import { setPatientProfile, setPatient } from '../../reducers/RxSlice';
import './Validate.css';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import { useDispatch } from 'react-redux';
import { PatientService } from '../../services/drsbee/data/PatientService';

const ValidateEncounter = () => {
    const patientService = new PatientService();
    const dispatch = useDispatch();
    const {hash} = useParams();
    const {uuid} = useParams();
    const redirectToLogin = () => {
        return <Navigate to="/signin" replace />;
    };    
    const validatePatient = (uuid) => {
        patientService.findPatientByHiveUUID(uuid).then(response => {
            if(response.successful && response.value!==null){
                if(response.value.mainPatientProfile===null){
                    dispatch(setPatient({id: "", firstName: response.value.firstName, lastName: response.value.lastName, identification: response.value.identification, identificationTypeCode: response.value.identificationTypeCode}));
                    return <Navigate to="/" replace />;
                }
                let profile = patientService.getPatientProfileById(response.value.mainPatientProfile);
                if(profile!==false){
                    profile.then(resp => {
                        if(resp.successful){
                            dispatch(setPatient({id: resp.value.id, firstName: resp.value.firstName, lastName: resp.value.lastName, identification: resp.value.identification, identificationTypeCode: resp.value.identificationTypeCode, birthDate: resp.value.birthDate}));
                            dispatch(setPatientProfile(patientService.cleanPatientProfile(resp.value)));                            
                            return <Navigate to="/" replace />;
                        }
                    })
                    .catch(err => console.log(err));
                }
            }
        });        
        return <Navigate to="/" replace />;        
    }

    return(
        <Container>
            <Row className='justify-content-center p-5'>
                {({hash}.hash!==null && {hash}.hash!=="undefined" && {uuid}.uuid!=="null") ? validatePatient({uuid}.uuid) : redirectToLogin()}
                <img src={ColegioCostaRica} style={{width: '15em'}} />
                <div className="d-flex justify-content-center"><LoadingSpinner /><p className="mx-1">Validating... Please wait</p></div>
            </Row>
        </Container>
    );
};
export default ValidateEncounter;