import React from 'react';
import CustomModal from '../../shared/CustomModal';
import { useNavigate } from 'react-router-dom';
import CheckoutList from './CheckoutList';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentOrder } from '../../../reducers/StoreSlice';

const Checkout = () => {
  const selectedQuote = useSelector((state) => state.store.selectedQuote);
  const selectedOrder = useSelector((state) => state.store.currentOrder);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goBack = () => {
    dispatch(setCurrentOrder(null));
    navigate('/store/cart');
  };

  const _getHeaderContent = () => (
    <>
      <div className="d-flex flex-row justify-content-center align-items-center">
        {selectedOrder !== null && selectedQuote !== null && (
          <img
            src={selectedQuote.pharmaceuticalGroupLogo.url}
            alt="Pharmacy Logo"
            style={{
              width: '5em',
              borderRadius: '0.4em',
              marginBottom: '0.3em',
              marginLeft: '0.4em',
              marginRight: '0.4em',
            }}
          />
        )}
        <h2 style={{ fontSize: '15pt' }}>Confirmar Pedido</h2>
      </div>
    </>
  );

  const _getBodyContent = () => (
    <>
      <CheckoutList />
    </>
  );

  return (
    <>
      <CustomModal
        title=""
        headerContent={_getHeaderContent()}
        bodyContent={_getBodyContent()}
        handleClose={goBack}
        show={selectedOrder !== null}
        fullBleed={false}
      />
    </>
  );
};

export default Checkout;
