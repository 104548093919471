import React, { useState } from "react";
import { Card } from 'react-bootstrap';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { Utilities } from "../../../utility/Utilities";
import OrderStatus from "./OrderStatus";

const OrderCard = ({ order }) => {
    const [expanded, setExpanded] = useState(false);
    const toggleExpanded = () => setExpanded(!expanded);
    const util = new Utilities();

    return (
        <Card style={{width: '80%', marginBottom: '2em', marginLeft: 'auto', marginRight: 'auto'}}>
            <Card.Header onClick={toggleExpanded} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img src={order.pharmaceuticalGroupLogo.url} alt={order.pharmaceuticalGroupLogo.alt} style={{height: '3em', marginRight: '1em', borderRadius: '0.6em'}} />
                    <h5 style={{fontSize: '10pt'}}>{util.formatDate(order.createDate)} - {order.totalPriceStr}</h5>
                </div>
                {expanded ? <FaAngleUp style={{color: '#401409'}}/> : <FaAngleDown  style={{color: '#401409'}}/>}
            </Card.Header>
            {expanded && (
                <Card.Body style={{textAlign: 'center'}}>                    
                    <OrderStatus status={order.orderStatusCodeText} />
                    <table className="table table-bordered" style={{fontSize: '9pt'}}>
                        <thead>
                            <tr>
                                <th>Producto</th>
                                <th>Precio</th>
                            </tr>
                        </thead>
                        <tbody>                            
                            {order.cartQuoteItems.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.presentationDescription}</td>
                                    <td style={{width: '8em'}}>{item.totalPriceStr}</td>    
                                </tr>                                            
                            ))}
                        </tbody>
                    </table>
                </Card.Body>
            )}
        </Card>
    );
};

export default OrderCard;