// AuthService.js

import { CURRENT_BACKEND } from "../config/BackendConfiguration";
const { Utilities } = require("../../../utility/Utilities");

export class AuthService {
    constructor() {
        this.backendConfiguration = CURRENT_BACKEND;
        this.util = new Utilities();
    }

    async loginAsPhysician(authHash) {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Basic " + authHash);
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            credentials: 'include',
        };

        const response = await fetch(`${this.backendConfiguration.baseUrl}/user/healthprofessional/login`, requestOptions);
        return this._handleResponse(response);
    }

    async loginAsPatient(authHash) {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Basic " + authHash);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            credentials: 'include',
        };

        const response = await fetch(`${this.backendConfiguration.baseUrl}/user/patient/login`, requestOptions);
        return this._handleResponse(response);
    }

    async loginWithGoogle(idToken) {
        const myHeaders = new Headers();
        myHeaders.append('id-token', idToken);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            credentials: 'include',
        };

        const response = await fetch(`${this.backendConfiguration.baseUrl}/user/google/patient/login`, requestOptions);
        return this._handleResponse(response);
    }



    async checkSession() {
        const fetchOptions = this.util.buildFetchOptions("GET", null);
        const response = await fetch(`${this.backendConfiguration.baseUrl}/session`, fetchOptions);
        return this._handleResponse(response);
    }

    async getSessionHeaders() {
        const fetchOptions = this.util.buildFetchOptions("GET", null);
        const response = await fetch(`${this.backendConfiguration.baseUrl}/session`, fetchOptions);
        if (response.ok) {
            return response.headers;
        } else {
            const errorText = await response.text();
            throw new Error(errorText || `HTTP error! Status: ${response.status}`);
        }
    }

    async getLoggedInPhysician() {
        const fetchOptions = this.util.buildFetchOptions("GET", null);
        const response = await fetch(`${this.backendConfiguration.baseUrl}/user/physician`, fetchOptions);
        return this._handleResponse(response);
    }

    buildPhysicianVM(physician) {
        const {
            birthDate,
            birthDateGlobal,
            address,
            email,
            phoneNumber,
            genderCode,
            genderDescription,
            nationalityCountryCode,
            nationalityCountryDescription,
            residenceCountryCode,
            residenceCountryDescription,
            profileImage,
            modifiedProfileImage,
            specialityFromCollege,
            phrase,
            ...physicianVM
        } = physician;
        return physicianVM;
    }

    validateSession() {
        return !(this.sessionInfo == null || this.sessionInfo.indexOf("NOUSER") !== -1);
    }

    setSessionInfo(sessionInfo) {
        this.sessionInfo = sessionInfo;
    }

    destroySession(noRefresh = false) {
        const data = ['physician', 'authorization'];
        if (noRefresh) data.push('br_token');
        data.forEach(element => window.localStorage.removeItem(element));
        document.cookie = "JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/drsbee;";
    }

    async logout() {
        const fetchOptions = this.util.buildFetchOptions("POST", null);
        const response = await fetch(`${this.backendConfiguration.baseUrl}/user/logout`, fetchOptions);
        return this._handleResponse(response);
    }

    async createGooglePatientUser(data) {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

        // Convert data object to URL-encoded form data
        const urlencoded = new URLSearchParams();
        for (const key in data) {
            if (data.hasOwnProperty(key) && data[key] !== null && data[key] !== undefined) {
                urlencoded.append(key, data[key]);
            }
        }

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded.toString(),
            credentials: 'include',
        };

        const response = await fetch(`${this.backendConfiguration.baseUrl}/user/patient/signup/googlerequest`, requestOptions);
        return this._handleResponse(response);
    }

    // Private method to handle responses
    async _handleResponse(response) {
        if (response.ok) {
            try {
                return await response.json();
            } catch (jsonError) {
                return {};
            }
        } else if (response.status === 401) {
            throw new Error('Unauthorized');
        } else {
            let errorMessage = `HTTP error! Status: ${response.status}`;
            try {
                const errorJson = await response.json();
                if (errorJson && errorJson.message) {
                    errorMessage = errorJson.message;
                }
            } catch (e) {
                const errorText = await response.text();
                if (errorText) {
                    errorMessage = errorText;
                }
            }
            throw new Error(errorMessage);
        }
    }


}
