import React, {useEffect, useState} from 'react';
import { Container } from 'react-bootstrap';
import CustomSearchInput from '../../components/shared/CustomSearchInput';
import CustomPill from '../../components/shared/CustomPill';
import CustomModal from '../../components/shared/CustomModal';
import SearchResults from '../../components/erx/SearchResults';
import { useNavigate } from 'react-router-dom';
import { DrugService } from '../../services/drsbee/data/DrugService'
import { useDispatch, useSelector } from 'react-redux';
import {setSearchPlaceholder} from '../../reducers/RxSlice';
import { Utilities } from '../../utility/Utilities';
const drugService = new DrugService();
const util = new Utilities();
const currentData = { medicines: [] }
const DrugSearchView = () => {
    const navigate = useNavigate();
    const [show, setShow] = useState(true);
    const [filter, setFilter] = useState(null);
    const [search, setSearch] = useState('');
    const [results, setResults] = useState(currentData.medicines);
    const dispatch = useDispatch();
    const patient = useSelector((state) => state.prescription.patientProfile);
    const handleClose = () => navigate(-1);
    const handleShow = () => setShow(true);
    useEffect(() => {
        dispatch(setSearchPlaceholder("Buscar medicamentos por nombre o principio activo"));
    });
    const stageResults = (queryResults) => {
        let newList = [];
        queryResults.forEach(element => {
            let drugItem = {id: element.id, name: element.suggestedDescription, content: '', quantity: '', doseUnitDescription: element.doseUnitPluralDescription, image: null, monograph: null, available: (element.availability!=null ? parseInt(element.availability) : 0), deletable: true, logo: null, unit: element.doseUnitPluralDescription, type: '', drugObj: element}            
            if(drugService.isDrugSponsored(element.description)){
                let media = drugService.getSponsoredMedia(element.description);
                drugItem.image = media["image"];
                if(typeof(media["monograph"])=='object'){
                    drugItem.monograph = media["monograph"][JSON.stringify(element.monograph.activeIngredientById["211"].strengthA)];
                }else{drugItem.monograph = media["monograph"];}
            }
            newList.push(drugItem);
        });
        setResults(newList);
    };
    const handleSearch = (val: string) => {
        if(val) {
            let res = drugService.findDrugsByFilterWithAvailability(val,util.getAgeFromBirthDate(patient.birthDate));
            if(res!==false){
                res.then(resp => stageResults(resp.value["drugsByName"]));
            }
            return;
        }
        setResults(currentData.medicines.filter( (medicine) => !filter || medicine.type === currentData.filters[filter]));
        setSearch(val);
    }

    const handleFilter = (index: number) => {
        if(index === filter){
            const filteredResults = currentData.medicines
                .filter( (medicine) => medicine.name.toLocaleLowerCase().includes(search.toLowerCase()) );
            setResults([...filteredResults]);
            setFilter(null);
            return;
        }
        const filteredResults = currentData.medicines
            .filter( (medicine) => medicine.name.toLocaleLowerCase().includes(search.toLowerCase()) )
        setResults([...filteredResults]);
        setFilter(index);
    }

    const _getFilters = () => {
        return(
            <>
                { currentData.filters.map(( label, index ) =>
                    <CustomPill
                        key={index}
                        index={index}
                        label={label}
                        backgroundColor={filter === index ? 'var(--unlisted-green)' : 'var(--unlisted-light-teal)'}
                        labelColor={filter === index ? 'var(--white)' : 'var(--unlisted-black)'}
                        action={() => handleFilter(index)}
                    />
                )}
            </>
        )
    }

    const _getHeaderContent = () => (
        <>
            <CustomSearchInput search={handleSearch} textHint="Buscar medicamentos"/>
        </>
    )

    const _getBodyContent = () => (
        <>
            <div className='d-flex flex-column'>
                <SearchResults medicines={results} />
            </div>
        </>
    )

    return (
        <Container style={{ paddingRight: 30, paddingLeft: 30 }}>
            <CustomModal
                title='Búsqueda'
                headerContent={_getHeaderContent()}
                bodyContent={_getBodyContent()}
                handleClose={handleClose}
                show={show}
                fullBleed={false}
            />
        </Container>
    )
}

export default DrugSearchView;
