import React from "react";
import styled from "styled-components";
import { FaCheck } from "react-icons/fa";

const OrderStatus = ({ status }) => {
    // Define the steps and the corresponding order status
    const steps = ["Recibido", "Preparado", "En camino", "Entregado"];

    return (
        <Container>
            {steps.map((step, index) => (
                <Step key={index} active={steps.indexOf(status) >= index}>
                    <StepIcon active={steps.indexOf(status) >= index}>
                        {steps.indexOf(status) >= index && (
                            <FaCheck style={{ color: 'white', fontSize: '12px' }} />
                        )}
                    </StepIcon>
                    <StepLabel>{step}</StepLabel>
                </Step>
            ))}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1em;
    position: relative;
    
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const Step = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    flex: 1;

    &:not(:last-child)::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 100%;
        width: 100%;
        height: 4px;
        background-color: #ccc;
        z-index: -1;
    }

    &:not(:first-child)::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 100%;
        width: 100%;
        height: 4px;
        background-color: ${props => props.active ? '#fdbf20' : '#ccc'};
        z-index: -1;
    }

    @media (max-width: 768px) {
        flex-direction: row;
        align-items: center;
        margin-bottom: 1em;

        &:not(:last-child)::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            width: 4px;
            height: 100%;
            background-color: #ccc;
        }

        &:not(:first-child)::before {
            content: '';
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            width: 4px;
            height: 100%;
            background-color: ${props => props.active ? '#fdbf20' : '#ccc'};
        }
    }
`;

const StepIcon = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: ${props => props.active ? '#fdbf20' : '#ccc'};
    border: ${props => props.active ? '4px solid #fdbf20' : '4px solid #ccc'};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5em;

    @media (max-width: 768px) {
        margin-right: 1em;
        margin-bottom: 0;
    }
`;

const StepLabel = styled.span`
    color: #333;
    font-size: 14px;
    text-align: center;

    @media (max-width: 768px) {
        text-align: left;
    }
`;

export default OrderStatus;
