// PaymentMethodsList.js

import React from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { changeSelectedPaymentMethod, removePaymentMethod } from '../../../reducers/StoreSlice';
import { FaRegCreditCard } from 'react-icons/fa';
import { PaymentService } from '../../../services/drsbee/data/PaymentService';
import Swal from 'sweetalert2'; // Import SweetAlert2
import { useAuth } from '../../../context/AuthContext';

const paymentService = new PaymentService();

const PaymentMethodsList = () => {
    const paymentMethods = useSelector((state) => state.store.paymentMethods);
    const selectedPaymentMethod = useSelector((state) => state.store.selectedPaymentMethod);
    const loggedInPatient = useSelector((state) => state.store.loggedInPatient);    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {authCookie, setAuthCookie} = useAuth();
    const isComingFromCheckout = useSelector((state) => state.store.isComingFromCheckout);

    const handlePaymentMethodChange = (event, pm) => {
        dispatch(changeSelectedPaymentMethod(pm));
        window.localStorage.setItem('selectedPaymentMethod', JSON.stringify(pm));
        if(isComingFromCheckout){
            navigate("/store/cart/checkout");
        } else {
            navigate("/store/cart");
        }
    };
    
    const handleAddPaymentMethod = () => {
        navigate("/store/cart/payment_methods/add");
    }

    const handleDeletePaymentMethod = () => {
        if(!selectedPaymentMethod){
            Swal.fire({
                icon: 'warning',
                title: 'No hay método de pago seleccionado',
                text: 'Por favor, selecciona un método de pago para eliminar.',
            });
            return;
        }
        console.log("selectedPaymentMethod", selectedPaymentMethod);
        Swal.fire({
            title: '¿Estás seguro?',
            text: `¿Quieres eliminar el método de pago seleccionado?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                if(loggedInPatient !== null){
                    paymentService.removePaymentMethod(selectedPaymentMethod, authCookie).then(response => {
                        if(response.successful && response.value){
                            dispatch(removePaymentMethod(selectedPaymentMethod));
                            Swal.fire(
                                'Eliminado',
                                'El método de pago ha sido eliminado.',
                                'success'
                            );
                        } else {
                            Swal.fire(
                                'Error',
                                'No se pudo eliminar el método de pago en el servidor.',
                                'error'
                            );
                        }
                    }).catch(error => {
                        console.error("Error al eliminar método de pago:", error);
                        Swal.fire(
                            'Error',
                            'Hubo un problema al eliminar el método de pago. Por favor, inténtalo de nuevo.',
                            'error'
                        );
                    });
                } else {
                    // Usuario invitado, eliminar localmente
                    dispatch(removePaymentMethod(selectedPaymentMethod));
                    Swal.fire(
                        'Eliminado',
                        'El método de pago ha sido eliminado.',
                        'success'
                    );
                }
            }
        });
    };

    const goBack = () => {
        if(isComingFromCheckout){
            navigate("/store/cart/checkout");
        }else{
            navigate(-1);
        }        
    }

    return (
        <div>
            <div className='d-flex flex-row justify-content-left align-items-center'>
                <Button variant="primary m-3" style={{backgroundColor: '#401409'}} onClick={() => goBack()}>
                    Volver
                </Button>
                <FaRegCreditCard style={{height: 35, width: 35, marginBottom: '1.2em', marginRight: '1em'}}/>
                <h2 style={{marginBottom: '0.8em',marginRight: '2em'}}>Métodos de pago</h2>
            </div>
            {paymentMethods.length === 0 ? (
                <Card>
                    <Card.Body>
                        <Card.Text>No tienes métodos de pago registrados.</Card.Text>
                    </Card.Body>
                </Card>
            ) : (
                paymentMethods.map((pm, index) => (
                    <Card key={pm.id} className="mb-3">
                        <Card.Body>
                            <Form.Check
                                type="radio"
                                name="paymentMethod"
                                id={`paymentMethod-${pm.id}`}
                                label={pm.vendorDescription ? `${pm.vendorDescription} - ${pm.maskedNumber}` : pm.maskedNumber}
                                checked={selectedPaymentMethod && selectedPaymentMethod.id === pm.id}
                                onChange={(e) => handlePaymentMethodChange(e, pm)}
                            />
                        </Card.Body>
                    </Card>
                ))
            )}
    
            <div className="d-flex justify-content-between align-items-center flex-row mt-3 p-4">
                <Button variant="primary" style={{backgroundColor: 'var(--yellow)', color: '#000000'}} onClick={handleAddPaymentMethod}>
                    Agregar
                </Button>
                {paymentMethods.length > 0 ? 
                    <Button variant="danger" onClick={handleDeletePaymentMethod}>
                        Eliminar
                    </Button>
                : null}
            </div>
        </div>
    );
};

export default PaymentMethodsList;
