import { CURRENT_BACKEND } from "../config/BackendConfiguration";

export class PrescriptionService{
    constructor(){
        this.backendConfiguration = CURRENT_BACKEND;
        this.authorization = window.localStorage.getItem('br_token');
    }
    beginEncounterNewPatient(identificationTypeCode, identification, firstName, lastName, birthDate, phoneNumber){
        var urlencoded = new URLSearchParams();
        var params = {"identificationTypeCode": identificationTypeCode,"identification": identification,"firstName": firstName, "lastName": lastName, "phoneNumber": phoneNumber, reason: "null"};
        var dateParts = ["birthdateYear","birthdateMonth","birthdateDay"];
        var birthDateSplitted = birthDate.split('-');
        dateParts.forEach(datePart => params[datePart] = birthDateSplitted[dateParts.indexOf(datePart)]);
        Object.keys(params).forEach(param => urlencoded.append(param, params[param]));
        var myHeaders = new Headers();
        myHeaders.append("Authorization", this.authorization);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow',
            credentials: 'include'
        };
        return fetch(this.backendConfiguration.baseUrl+"/encounter/begin", requestOptions)
        .then(response => response.json());
    }
    beginEncounter(patient){
        var urlencoded = new URLSearchParams();
        if(patient.id===null || patient.id===""){
            urlencoded.append("identification", patient.identification);
            urlencoded.append("identificationTypeCode", patient.identificationTypeCode);
            urlencoded.append("reason", "null");
        }else{ urlencoded.append("patientId", patient.id);}
        var myHeaders = new Headers();
        myHeaders.append("Authorization", this.authorization);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow',
            credentials: 'include'
        };
        return fetch(this.backendConfiguration.baseUrl+"/encounter/begin", requestOptions)
        .then(response => response.json());
    }
    getEncounter(encounterId){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", this.authorization);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
            credentials: 'include'
        };
        return fetch(this.backendConfiguration.baseUrl+"/encounter/"+encounterId, requestOptions)
        .then(response => response.json());
    }
    prescriptionCheck(encounterVM){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", this.authorization);
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(encounterVM),
            redirect: 'follow',
            credentials: 'include'
        };
        return fetch(this.backendConfiguration.baseUrl+"/encounter/prescription/check", requestOptions).then(data => data.json());
    }
    prescriptionReview(reviewVM){
        console.log(reviewVM);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", this.authorization);
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(reviewVM),
            redirect: 'follow',
            credentials: 'include'
        };
        return fetch(this.backendConfiguration.baseUrl+"/encounter/prescription/review", requestOptions).then(data => data.json());
    }
    finishEncounter(finishVM){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", this.authorization);
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(finishVM),
            redirect: 'follow',
            credentials: 'include'
        };
        return fetch(this.backendConfiguration.baseUrl+"/encounter/finish", requestOptions).then(data => data.json());
    }
    cleanEncounterObject(encounter){
        let {code,prescriptionDate,diagnostics,healthProblemReferences,drugs,nonDrugs,references,patientPaymentDate,patientPaymentDismissed,patientPaymentTransactionInfo,patientPaymentTransactionExternalReference,drsbeePaymentDate,drsbeePaymentTransactionInfo,consultCost,prescriptionCost,othersCost,consultCostStr,prescriptionCostStr,othersCostStr,totalCost,totalCostStr,adminCommissionReport,empty,...cleanEncounter} = encounter.encounter.prescription;
        return cleanEncounter;
    }
    buildFinishViewModel(encounterVM,email,phoneNumber,consultCost,prescriptionCost){
        let {prescription, ...cleanEncounterVM} = encounterVM;
        var finishVM = {
            emailNotifications: [],
            encounter: cleanEncounterVM,
            smsNotifications: [],
            ehr: {
                drugTreatments: [],
                healthProblems: [],
                patientClinicalData: {
                    weight: 76,
                    weightLastModificationDate: '0001-01-01T00:00:00Z',
                    weightCalculated: true,
                    phoneNumber: phoneNumber,
                    email: email
                },
                nonDrugTreatments: [],
                allergies: [],
                patientMedicalForms: [],
                patientAttachments: []
            },
            locationLatitude: 8.27808009061397,
            locationLongitude: -81.7677390648415,
            consultCost: consultCost,
            prescriptionCost: prescriptionCost,
            othersCost: 0
        };
        return finishVM;
    }
    buildReviewViewModel(encounter){
        var reviewVM = {
            drugs: encounter.prescription.drugs,
            nonDrugs: [],
            healthProblemReferences: [],
            encounterId: encounter.id,
            forceSignature: false
        }
        return reviewVM;
    }
    buildCheckViewModel(encounter){
        var checkVM = {
            additionalDrugIdByVademecumId: {},
            encounter: encounter,
            ehrModifications: {
              drugTreatments: [],
              healthProblems: [],
              nonDrugTreatments: [],
              allergies: [],
              patientMedicalForms: [],
              patientAttachments: []
            },
            checkOutdatedDrugs: false,
            considerSelectedAdmRouteForInteractions: false
        }
        return checkVM;
    }
    buildEncounterViewModel(encounter, patientProfile,prescriptionVM){
        var physician = JSON.parse(window.localStorage.getItem('physician'));
        var encounterVM = {
            accessToPatientProfile: encounter.accessToPatientProfile,
            writeToPatientProfile: encounter.writeToPatientProfile,
            patientRegistered: encounter.patientRegistered,
            patientAutopaymentEnabled: encounter.patientAutopaymentEnabled,
            statusCode: encounter.statusCode,
            physicianIdentification: physician.identification,
            physicianIdentificationTypeCode: physician.identificationTypeCode,
            physicianAddress: physician.doctorOffices[0].address,
            physicianPhoneNumber: physician.doctorOffices[0].phone,
            physicianEmail: physician.email,
            patientIdentification: patientProfile.identification,
            patientIdentificationTypeCode: patientProfile.identificationTypeCode,
            physicianNameTitle: physician.nameTitle,
            patientName: patientProfile.firstName + " " + patientProfile.lastName,
            patientBirthDate: patientProfile.birthDate,
            physicianName: physician.firstName+" "+physician.lastName,
            encounterMedicalForms: [],
            physicianMedicalSpeciality: physician.specialityDescription,
            physicianId: physician.id,
            date: encounter.date,
            statusDescription: encounter.statusDescription,
            id: encounter.id,
            patientId: patientProfile.id,
            patientProfile: patientProfile,
            prescription: prescriptionVM,
            patientClinicalData: {
                weight: 90,
                weightLastModificationDate: '0001-01-01T00:00:00Z',
                weightCalculated: true
            }
        };
        return encounterVM;
    }
    buildPrescriptionViewModel(drugVMList){
        var prescriptionVM = {
            references: [],
            nonDrugs: [],
            drugs: drugVMList,
            diagnostics: [],
            healthProblemReferences: [],
            patientPaymentDate: '0001-01-01T00:00:00Z',
            drsbeePaymentDate: '0001-01-01T00:00:00Z',
            consultCost: 0,
            prescriptionCost: 0,
            othersCost: 0,
            patientPaymentDismissed: false,
            totalCost: 0
        };
        return prescriptionVM;
    }

}
