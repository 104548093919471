import React from "react";
import { useSelector } from "react-redux";
import AddressList from "../../components/store/addresses/AddressList";
import PageWrapper from "../../components/shared/PageWrapper";

const Addresses = () => {    
    const orderItems = useSelector((state) => state.store.orderItems);

    return (
        <PageWrapper selectedMenuOption="addresses" orderItemsCount={orderItems.length}>
            <AddressList />
        </PageWrapper>
    );
};

export default Addresses;
