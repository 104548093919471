import { CURRENT_BACKEND } from "../services/drsbee/config/BackendConfiguration";

export class NativeUtilities{
    constructor(){}
    
    isRunningOnWebView(){
        return CURRENT_BACKEND.IsWebView && window.Hive !== null;
    }
    
    savePref(key, value){
        if(this.isRunningOnWebView() && typeof window.Hive === 'object' && typeof window.Hive.extractToTemp === 'function'){
            window.Hive.extractToTemp(key, value);
        }else{
            window.localStorage.setItem(key, value);
        }
    }
    
    getPref(key){
        if(this.isRunningOnWebView() && typeof window.Hive === 'object' && typeof window.Hive.readNativePref === 'function'){
            return window.Hive.readNativePref(key);
        }else{
            return window.localStorage.getItem(key);
        }
    }
    
    closeView(){
        if(this.isRunningOnWebView() && typeof window.Hive === 'object' && typeof window.Hive.closeWebview === 'function'){
            window.Hive.closeWebview();
        }else{
            window.close();
        }
    }
    
    launchNativeLogin(){
        if(this.isRunningOnWebView() && typeof window.Hive === 'object' && typeof window.Hive.launchSignIn === 'function'){
            window.Hive.launchSignIn();
        }
    }
    
    launchAppLobby(){
        if(this.isRunningOnWebView() && typeof window.Hive === 'object' && typeof window.Hive.launchLobby === 'function'){
            window.Hive.launchLobby();
        }
    }
    
    getGeoLocation(){
        if(this.isRunningOnWebView() && typeof window.Hive === 'object' && typeof window.Hive.getGeolocation === 'function'){
            window.Hive.getGeolocation();
        }else{
            this.getIPLocation((data) => {
                if(window.localStorage.getItem('currentLat') === null || window.localStorage.getItem('currentLng') === null){
                    window.localStorage.setItem('currentLat', data.lat);
                    window.localStorage.setItem('currentLng', data.lon);
                }
            });
        }
    }
    
    getIPLocation(callback){
        fetch("http://ip-api.com/json")
            .then(response => response.json())
            .then(data => callback(data))
            .catch(() => {
                callback({ lat: '9.934668069261619', lon: '-84.08104447952564' }); // Fallback to the original default location
            });
    }
    deleteAllCookies(){
        const hostname = window.location.hostname;
        let expirationCookie = `JSESSIONID=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/drsbee; domain=${hostname};`;
        document.cookie = expirationCookie;
    };
    
};
