// storeSlice.js

import { createSlice, createAction } from '@reduxjs/toolkit';
import { AddressService } from '../services/drsbee/data/AddressService';
import { ShoppingCartService } from '../services/drsbee/data/ShoppingCartService';
import { Utilities } from '../utility/Utilities';
import { NativeUtilities } from '../utility/NativeUtilities';

const util = new Utilities();
const nativeUtil = new NativeUtilities();
const addressService = new AddressService();
const shoppingCartService = new ShoppingCartService();

const extractToTemp = (reduxState) => {
  // Implement if needed
};

const restoreStateFromLocalStorage = (state) => {
  // Implement if needed
};

export const restoreStateAction = createAction('RESTORE_STATE');

const getInitialState = () => ({
  environment: null,
  doseUnits: [],
  loggedInPatient: JSON.parse(window.localStorage.getItem('loggedInPatient')) || null,
  guestUserData: {},
  pharmacies: [],
  orderItems: JSON.parse(window.localStorage.getItem('orderItems')) || [],
  cacheItems: JSON.parse(window.localStorage.getItem('cacheItems')) || [],
  tempItems: JSON.parse(window.localStorage.getItem('tempItems')) || null,
  prescriptionItems: [],
  storeItems: [],
  addresses: [],
  paymentMethods: [],
  quotes: [],
  selectedPaymentMethod: JSON.parse(window.localStorage.getItem('selectedPaymentMethod')) || null,
  selectedAddress: JSON.parse(window.localStorage.getItem('selectedAddress')) || null,
  selectedQuote: null,
  currentOrder: null,
  addedPrescriptions: JSON.parse(window.localStorage.getItem('addedPrescriptions')) || [],
  currentPrescriptionId: null,
  user: null,
  orderSubtotal: 0,
  orderDiscount: 0,
  orderShipping: 0,
  orderTotal: 0,
  redirectToQuotes: false,
  isComingFromCheckout: false,
  performRequote: false,
  spinCartIcon: false,
});

export const storeSlice = createSlice({
  name: 'cart',
  initialState: getInitialState(),
  reducers: {
    setLoggedInPatient: (state, action) => {
      state.loggedInPatient = action.payload;
      window.localStorage.setItem('loggedInPatient', JSON.stringify(action.payload));
    },
    setGuestUserData: (state, action) => {
      state.guestUserData = action.payload;
    },
    setCurrentOrder: (state, action) => {
      state.currentOrder = action.payload;
    },
    setCurrentPrescriptionId: (state, action) => {
      state.currentPrescriptionId = action.payload;
    },
    setEnvironment: (state, action) => {
      state.environment = action.payload;
    },
    setAddresses: (state, action) => {
      state.addresses = action.payload;
    },
    setPaymentMethods: (state, action) => {
      state.paymentMethods = action.payload;
    },
    setPharmacies: (state, action) => {
      state.pharmacies = action.payload;
    },
    setQuotes: (state, action) => {
      let quotes = [];
      Object.keys(action.payload).forEach(quoteId => quotes.push(action.payload[quoteId]));
      state.quotes = util.sortQuotes(quotes);
    },
    addPaymentMethod: (state, action) => {
      state.paymentMethods.push(action.payload);
    },
    removePaymentMethod: (state, action) => {
      const index = state.paymentMethods.findIndex(pm => pm.id === action.payload.id);
      if (index !== -1) {
        const removedPaymentMethod = state.paymentMethods[index];
        state.paymentMethods.splice(index, 1);

        if (state.selectedPaymentMethod && state.selectedPaymentMethod.id === removedPaymentMethod.id) {
          if (state.paymentMethods.length > 0) {
            state.selectedPaymentMethod = state.paymentMethods[Math.min(index, state.paymentMethods.length - 1)];
            window.localStorage.setItem('selectedPaymentMethod', JSON.stringify(state.selectedPaymentMethod));
          } else {
            state.selectedPaymentMethod = null;
            window.localStorage.removeItem('selectedPaymentMethod');
          }
        }
      }
    },
    addAddress: (state, action) => {
      state.addresses.push(action.payload);
    },
    setRedirectToQuotes: (state, action) => {
      state.redirectToQuotes = action.payload;
    },
    setPerformRequote: (state, action) => {
      state.performRequote = action.payload;
    },
    setIsComingFromCheckout: (state, action) => {
      state.isComingFromCheckout  = action.payload;
    },
    removeAddress: (state, action) => {
      const index = state.addresses.findIndex(addr => addr.id === action.payload.id);
      if (index !== -1) {
        const removedAddress = state.addresses[index];
        state.addresses.splice(index, 1);
        if (state.selectedAddress && state.selectedAddress.id === removedAddress.id) {
          if (state.addresses.length > 0) {
            state.selectedAddress = state.addresses[0];
            window.localStorage.setItem('selectedAddress', JSON.stringify(state.selectedAddress));
          } else {
            state.selectedAddress = null;
            window.localStorage.removeItem('selectedAddress');
          }
        }
      }
    },
    addItemToOrder: (state, action) => {
      state.orderItems.push(action.payload);
      state.orderSubtotal += action.payload.price;
      state.orderTotal = state.orderSubtotal - state.orderDiscount + state.orderShipping;
      window.localStorage.setItem('orderItems', JSON.stringify(state.orderItems));
    },
    addPrescriptionToOrder: (state, action) => {
      const existingPrescription = state.addedPrescriptions.find(
        (prescription) => prescription.prescriptionId === action.payload.prescriptionId
      );
      if (!existingPrescription) {
        state.addedPrescriptions.push(action.payload);
        window.localStorage.setItem(
          'addedPrescriptions',
          JSON.stringify(state.addedPrescriptions)
        );
      }
    },
    removePrescriptionFromOrder: (state, action) => {
      state.addedPrescriptions = state.addedPrescriptions.filter(
        (prescription) => prescription.prescriptionId !== action.payload
      );
      window.localStorage.setItem(
        'addedPrescriptions',
        JSON.stringify(state.addedPrescriptions)
      );
    },
    updatePrescriptionItemQuantity: (state, action) => {
      const prescriptionIndex = state.addedPrescriptions.findIndex(
        (prescription) => prescription.prescriptionId === action.payload.prescriptionId
      );
      if (prescriptionIndex !== -1) {
        state.addedPrescriptions[prescriptionIndex].quantity = action.payload.quantity;
        window.localStorage.setItem(
          'addedPrescriptions',
          JSON.stringify(state.addedPrescriptions)
        );
      }
    },
    resetOrderPrescriptions: (state) => {
      state.addedPrescriptions = [];
      window.localStorage.removeItem('addedPrescriptions');
    },
    addItemToCache: (state, action) => {
      state.cacheItems.push(action.payload);
      window.localStorage.setItem('cacheItems', JSON.stringify(state.cacheItems));
    },
    setOrderItems: (state, action) => {
      state.orderItems = action.payload;
      window.localStorage.setItem('orderItems', JSON.stringify(state.orderItems));
    },
    setCacheItems: (state, action) => {
      state.cacheItems = action.payload;
      window.localStorage.setItem('cacheItems', JSON.stringify(state.cacheItems));
    },
    setTempItems: (state, action) => {
      state.tempItems = action.payload;
      if(action.payload !== null) {
        window.localStorage.setItem('tempItems', JSON.stringify(action.payload));
      }
    },
    removeItemFromOrder: (state, action) => {
      const itemIndex = state.orderItems.findIndex((item) => item.id === action.payload);
      if (itemIndex !== -1) {
        const removedItem = state.orderItems[itemIndex];
        state.orderItems.splice(itemIndex, 1);
        state.orderSubtotal -= removedItem.price;
        state.orderTotal = state.orderSubtotal - state.orderDiscount + state.orderShipping;
        window.localStorage.setItem('orderItems', JSON.stringify(state.orderItems));
      }
    },
    updateQuotes: (state, action) => {
      state.quotes = action.payload;
    },
    updateOrderTotal: (state) => {
      state.orderTotal = state.orderSubtotal - state.orderDiscount + state.orderShipping;
    },
    changeSelectedPaymentMethod: (state, action) => {
      state.selectedPaymentMethod = action.payload;
      if(action.payload !== null) {
        window.localStorage.setItem('selectedPaymentMethod', JSON.stringify(action.payload));
      } else {
        window.localStorage.removeItem('selectedPaymentMethod');
      }
    },
    changeSelectedAddress: (state, action) => {
      state.selectedAddress = action.payload;
      if(action.payload !== null && action.payload.latitude !== null && action.payload.longitude !== null) {
        window.localStorage.setItem('selectedAddress', JSON.stringify(action.payload));
        window.localStorage.setItem('currentLat', action.payload.latitude.toString());
        window.localStorage.setItem('currentLng', action.payload.longitude.toString());
      } else {
        window.localStorage.removeItem('selectedAddress');
        window.localStorage.removeItem('currentLat');
        window.localStorage.removeItem('currentLng');
      }
    },
    changeSelectedQuote: (state, action) => {
      state.selectedQuote = action.payload;      
    },
    triggerCartSpin: (state) => {
      state.spinCartIcon = true;
    },
    resetCartSpin: (state) => {
      state.spinCartIcon = false;
    },
    resetState: (state) => {
      Object.assign(state, getInitialState());
    },
    performLogout : (state) => {
      state.addresses = [];
      state.paymentMethods = [];
      state.orderItems = [];
      state.cacheItems = [];
      state.tempItems = null;
      state.prescriptionItems = [];
      state.addedPrescriptions = [];
      state.storeItems = [];
      state.quotes = [];
      state.selectedPaymentMethod = null;
      state.selectedAddress = null;
      state.selectedQuote = null;
      state.loggedInPatient = null;    
      window.localStorage.removeItem('loggedInPatient');
      window.localStorage.removeItem('orderItems');
      window.localStorage.removeItem('cacheItems');
      window.localStorage.removeItem('tempItems');
      window.localStorage.removeItem('selectedPaymentMethod');
      window.localStorage.removeItem('addedPrescriptions');
      window.localStorage.removeItem('selectedAddress');
      window.localStorage.removeItem('addedPrescriptions');
      window.localStorage.removeItem('currentLat');
      window.localStorage.removeItem('currentLng');
    },
    saveTestState: (state, action) => {
      // Implement if needed
    },
  },
  extraReducers: (builder) => {
    builder.addCase(restoreStateAction, (state, action) => {
      restoreStateFromLocalStorage(state);
    });
  },
});

export const {
  addAddress,
  addPaymentMethod,
  addItemToOrder,
  addItemToCache,
  addPrescriptionToOrder,
  removePrescriptionFromOrder,
  updatePrescriptionItemQuantity,
  resetOrderPrescriptions,
  removeItemFromOrder,
  updateOrderTotal,
  updateQuotes,
  removePaymentMethod,
  removeAddress,
  changeSelectedAddress,
  changeSelectedQuote,
  changeSelectedPaymentMethod,
  setPharmacies,
  setAddresses,
  setOrderItems,
  setCacheItems,
  setTempItems,
  setQuotes,
  setPaymentMethods,
  setEnvironment,
  setCurrentOrder,
  setCurrentPrescriptionId,
  setLoggedInPatient,
  setGuestUserData,
  resetState,
  saveTestState,
  setRedirectToQuotes,
  setPerformRequote,
  setIsComingFromCheckout,
  triggerCartSpin,
  resetCartSpin,
  performLogout,
} = storeSlice.actions;

export default storeSlice.reducer;
