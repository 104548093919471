import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import dayjs from 'dayjs';
import OrderCard from "../../components/store/cart/OrderCard";
import { ShoppingCartService } from '../../services/drsbee/data/ShoppingCartService';
import PageWrapper from "../../components/shared/PageWrapper";
const shoppingCartService = new ShoppingCartService();

const HistoryView = () => {    
    const authCookie = useAuth();
    const orderItems = useSelector((state) => state.store.orderItems);
    const [orderHistory, setOrderHistory] = useState([]);
    const [selectedRange, setSelectedRange] = useState('Ultima semana');

    const dateRanges = {
        "Ultima semana": dayjs().subtract(1, 'week'),
        "Ultimo mes": dayjs().subtract(1, 'month'),
        "Ultimos 3 meses": dayjs().subtract(3, 'month'),
        "Ultimos 6 meses": dayjs().subtract(6, 'month'),
        "Ultimo año": dayjs().subtract(1, 'year'),
    };

    const fetchOrderHistory = (range) => {
        const today = dayjs();
        const fromDate = dateRanges[range];
        
        shoppingCartService.getOrderHistory(
            fromDate.date(),
            fromDate.month() + 1, // months are 0 indexed in dayjs
            fromDate.year(),
            today.date(),
            today.month() + 1,
            today.year(),
            authCookie
        ).then(response => {
            if (response.successful && response.value !== null) {
                // Sort orders by date (most recent first)
                const sortedOrders = response.value.sort((a, b) => {
                    const dateA = dayjs(a.createDate); // Replace 'date' with your actual date field
                    const dateB = dayjs(b.createDate);
                    return dateB.diff(dateA); // Recent orders first
                });
                setOrderHistory(sortedOrders);
            }
        });
    };

    useEffect(() => {
        fetchOrderHistory(selectedRange);
    }, [selectedRange]);

    return (
        <PageWrapper selectedMenuOption="history" orderItemsCount={orderItems.length}>
            <h3 style={{ textAlign: 'center', marginTop: '2em', color: '#401409' }}>Historial de Compras</h3>
            <DateRangeSelector>
                <label style={{ marginRight: '1em', marginTop: '0.5em', color: '#401409' }}>Mostrar:</label>
                <select 
                    value={selectedRange} 
                    onChange={(e) => setSelectedRange(e.target.value)} 
                    style={{ padding: '0.5em', borderRadius: '0.5em', borderColor: '#fdbf20', color: '#401409' }}
                >
                    {Object.keys(dateRanges).map(range => (
                        <option key={range} value={range}>{range}</option>
                    ))}
                </select>
            </DateRangeSelector>
            <div style={{ overflowY: 'scroll', marginTop: '2em' }}>
                {orderHistory.map((order, index) => (
                    <OrderCard key={index} order={order} style={{ marginBottom: '2em', marginTop: '2em' }} />
                ))}
            </div>
        </PageWrapper>
    );
};

const DateRangeSelector = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 1em;
`;

export default HistoryView;
