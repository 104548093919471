import React from 'react';
import DrugImg from '../../assets/dummy/vial.png';
import HiveCheckIcon from '../../assets/dummy/hive-check.png';
import { IoLocationSharp } from 'react-icons/io5';
import Fischel from '../../assets/logos/fischel.svg';
import CustomButton from '../shared/CustomButton';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentDrug } from '../../reducers/RxSlice';

/**
 * SearchResults
 * @param medicines Array of medicine items
 * @returns {JSX.Element}
 * @constructor
 */
 interface Medicine {
    id: number,
    name: string,
    content: string,
    quantity: string,
    image?: string,
    monograph?: string,
    available: number,
    doseUnitDescription: string,
    logo?: string
}

interface SearchResultsProps {
    medicines: Medicine[];
}


const SearchResults = ({ medicines }: SearchResultsProps) => {
    const lastResults = useSelector((state) => state.prescription.lastResults);
    const dispatch = useDispatch();
    const selectDrug = (id: string) => {
        for(var x=0;x<lastResults.length;x++){
            if(lastResults[x].id.toString()==id){
                dispatch(setCurrentDrug(lastResults[x]));
            }
        }
    }
    const _getMedicineInfo = (medicine: Medicine) => {
        return(
            <div className='d-flex flex-column' style={{ flex: 0.72, flexBasis: 'max-content' }}>
                <div className='d-flex flex-row'>
                    <div className='d-flex px-1 align-items-center' style={{ flex: '0 0 0%', fontSize: 14 }}>
                        <img src={medicine.image!==null? require(`../../assets/vademecum/img/${medicine.image}`) : DrugImg} alt='medicine' width={medicine.image!=null? 50 : 17} height={medicine.image!=null? 30 : 37} />
                    </div>
                    <div className='d-flex flex-column'
                        style={{ flex: '2 0 0%', fontSize: '8pt', marginTop: '0.7em', lineHeight: '0.7rem', color: 'var(--unlisted-black)' }}>
                        {medicine.monograph!==null?
                            <Link to={`/drug/monograph/${btoa(medicine.monograph)}`}><span className='bold' style={{color: '#0000FF'}}>{medicine.name}</span></Link>
                            :
                            <span className='bold'>{medicine.name}</span>
                        
                        }
                        <span className='ps-2 py-2' style={{ fontSize: '7pt'}}>
                            {medicine.available ? <img src={HiveCheckIcon} width={15} height={15} /> : null}
                            <span className='bold ps-1' style={{ color: medicine.available ? 'var(--unlisted-green)' : 'var(--unlisted-red)'}}>{medicine.available}</span>
                            <span className='ps-1'>{medicine.doseUnitDescription} disponibles</span>
                        </span>
                    </div>
                </div>
            </div>
        )
    }
    
    const _getResultsActions = (medicine: Medicine, index: number) => {
        return(
            <div
                className='d-flex flex-row justify-content-between align-items-center'
                style={{ flex: 0.27}}>
                <Link  to={`/rx/append/${medicine.id}`}>
                <CustomButton
                    type='icon'
                    color='primary'
                    disabled={false}
                    action={() => dispatch(setCurrentDrug(medicine))}
                    icon={<FaPlus color='var(--black)' style={{ marginBottom: 4, height: 11 }} />}
                />
                </Link>
            </div>
        )
    }
    return(
        <>
            { medicines.map((medicine, index) =>
                <div
                    key={index}
                    className='d-flex flex-row py-2 align-items-center justify-content-center mx-3'
                    style={{ borderTop: '1px solid #CCCCCC', opacity: 1}}
                >
                    {_getMedicineInfo(medicine)}
                    {_getResultsActions(medicine,index)}
                </div>
            )}
        </>
    )
}

export default SearchResults;
