import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import ShoppingCartList from '../../components/store/cart/ShoppingCartList';
import PurchaseInfo from '../../components/store/payment/PurchaseInfo';
import { ShoppingCartService } from '../../services/drsbee/data/ShoppingCartService';
import {
  setCurrentOrder,
  setOrderItems,
  setCacheItems,
  addPrescriptionToOrder,
  setPerformRequote,
} from '../../reducers/StoreSlice';
import { useAuth } from '../../context/AuthContext';
import Swal from 'sweetalert2';
import styled from 'styled-components';
import PageWrapper from '../../components/shared/PageWrapper';
import { RequoteService } from '../../services/drsbee/data/RequoteService';

const shoppingCartService = new ShoppingCartService();

const CartView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { privateKey } = useParams();
  const orderItems = useSelector((state) => state.store.orderItems);
  const cacheItems = useSelector((state) => state.store.cacheItems);
  const selectedAddress = useSelector((state) => state.store.selectedAddress);
  const selectedPaymentMethod = useSelector((state) => state.store.selectedPaymentMethod);
  const selectedQuote = useSelector((state) => state.store.selectedQuote);
  const { authCookie } = useAuth();
  const loggedInPatient = useSelector((state) => state.store.loggedInPatient);
  const addedPrescriptions = useSelector((state) => state.store.addedPrescriptions);
  const [authenticatedDepsReady, setAuthenticatedDepsReady] = useState(false);
  const [guestDepsReady, setGuestDepsReady] = useState(false);
  const performRequote = useSelector((state) => state.store.performRequote);
  const addresses = useSelector((state) => state.store.addresses);

  // UseRef flags to prevent multiple calls
  const authenticatedPrescriptionAdded = useRef(false);
  const guestPrescriptionAdded = useRef(false);

  const confirmOrder = async () => {
    if (selectedQuote && selectedAddress && selectedPaymentMethod) {
      try {
        let response = await shoppingCartService.createOrderFromQuote(
          selectedQuote.id,
          loggedInPatient && authCookie ? authCookie : null
        );

        if (response.successful && response.value !== null) {
          dispatch(setCurrentOrder(response.value));
          navigate('/store/cart/checkout');
        } else if (response.errorMessages[0].message === 'Orden expirada') {
          await Swal.fire({
            title: 'Cotización expirada',
            text: 'Tu cotización ha expirado, se volverá a generar la cotización para que puedas continuar.',
            icon: 'warning',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#fdbf20',
          });

          await RequoteService.requoteOrder(
            {
              loggedInPatient,
              authCookie,
              cacheItems,
              orderItems,
              selectedQuote,
              selectedAddress,
              addresses,
              dispatch,
              isRequoteTriggeredByCartChange: false,
              isRequoteTriggeredByLocationChange: false,
            },
            navigate
          );

          // Retry order creation
          response = await shoppingCartService.createOrderFromQuote(
            selectedQuote.id,
            loggedInPatient && authCookie ? authCookie : null
          );

          if (response.successful && response.value !== null) {
            dispatch(setCurrentOrder(response.value));
            navigate('/store/cart/checkout');
          } else {
            Swal.fire({
              title: 'Error',
              text: 'No se pudo crear el pedido después de actualizar la cotización.',
              icon: 'error',
              confirmButtonText: 'Aceptar',
              confirmButtonColor: '#fdbf20',
            });
          }
        } else {
          Swal.fire({
            title: 'Error',
            text: 'No se pudo crear el pedido.',
            icon: 'error',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#fdbf20',
          });
        }
      } catch (error) {
        console.error('Error creating order:', error);
        Swal.fire({
          title: 'Error',
          text: 'Ocurrió un error al crear el pedido.',
          icon: 'error',
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#fdbf20',
        });
      }
    } else {
      let pendingStepsNum = 1;
      let pendingSteps = [];
      if (selectedQuote === null) {
        pendingSteps.push(pendingStepsNum++ + '. Selecciona una farmacia y cotización');
      }
      if (selectedAddress === null) {
        pendingSteps.push(pendingStepsNum++ + '. Agrega una dirección de entrega');
      }
      if (selectedPaymentMethod === null) {
        pendingSteps.push(pendingStepsNum++ + '. Especifica un método de pago');
      }
      Swal.fire({
        title: 'Pasos pendientes',
        html:
          'Por favor completa los siguientes pasos antes de confirmar el pedido:<br>' +
          pendingSteps.join('<br>'),
        icon: 'warning',
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#fdbf20',
      });
    }
  };

  const showDuplicatePrescriptionAlert = () => {
    Swal.fire({
      title: 'Prescripción previamente agregada',
      text: 'Esta prescripción ya ha sido agregada al carrito.',
      icon: 'info',
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#fdbf20',
    }).then((result) => {
      if (result.isConfirmed) {
        navigate('/store/cart');
      }
    });
  };

  const isLoadedToCart = (privateKey) => {
    return addedPrescriptions.some(
      (prescription) => prescription.privateKey === privateKey
    );
  };

  useEffect(() => {
    if (
      privateKey !== undefined &&
      privateKey !== null &&
      authCookie !== null &&
      loggedInPatient !== null
    ) {
      setAuthenticatedDepsReady(true);
    }
  }, [privateKey, authCookie, loggedInPatient]);

  useEffect(() => {
    if (
      privateKey !== undefined &&
      privateKey !== null &&
      loggedInPatient === null &&
      authCookie === null
    ) {
      setGuestDepsReady(true);
    }
  }, [privateKey, loggedInPatient, authCookie]);

  useEffect(() => {
    if (cacheItems !== undefined && orderItems !== undefined) {
      if (cacheItems.length > orderItems.length) {
        dispatch(setOrderItems(cacheItems));
      }
    }
  }, [cacheItems, orderItems, dispatch]);

  useEffect(() => {
    if (authenticatedDepsReady && privateKey && !authenticatedPrescriptionAdded.current) {
      authenticatedPrescriptionAdded.current = true; // Set the flag to prevent re-execution
      if (isLoadedToCart(privateKey)) {
        showDuplicatePrescriptionAlert();
      } else {
        if (loggedInPatient !== null && authCookie !== null) {
          shoppingCartService
            .addPrescriptionToCart(privateKey, authCookie)
            .then((response) => {
              if (response.successful && response.value !== null) {
                dispatch(
                  setOrderItems(response.value.items.filter((item) => item.status === '1'))
                );
                const prescription = response.value;
                const activeItems = prescription.items.filter((item) => item.status === '1');
                const itemsQty = activeItems.filter(
                  (item) => item.prescriptionId.toString() === prescription.prescriptionIdAccess
                ).length;
                const prescriptionObject = {
                  prescriptionId: prescription.prescriptionIdAccess,
                  privateKey: privateKey,
                  itemsQty: itemsQty,
                  previewUrl: prescription.previewFile ? prescription.previewFile.url : null,
                };
                dispatch(addPrescriptionToOrder(prescriptionObject));
                navigate('/store/cart');
              }
            });
        }
      }
    }
  }, [authenticatedDepsReady, privateKey, authCookie, loggedInPatient, dispatch, navigate]);

  useEffect(() => {
    if (guestDepsReady && privateKey && !guestPrescriptionAdded.current) {
      guestPrescriptionAdded.current = true; // Set the flag to prevent re-execution
      if (isLoadedToCart(privateKey)) {
        showDuplicatePrescriptionAlert();
      } else {
        const currentDate = new Date().toISOString();
        const cachedCart = {
          id: '',
          lastModificationDate: currentDate,
          createDate: currentDate,
          subtotal: null,
          discount: null,
          tax: null,
          total: null,
          items: cacheItems,
        };
        shoppingCartService
          .addPrescriptionToCachedCart(privateKey, cachedCart)
          .then((response) => {
            if (response.successful && response.value !== null) {
              dispatch(setCacheItems(response.value.items));
              dispatch(setOrderItems(response.value.items));
              const prescription = response.value;
              const itemsQty = prescription.items.filter(
                (item) => item.prescriptionId.toString() === prescription.prescriptionIdAccess
              ).length;
              const prescriptionObject = {
                prescriptionId: prescription.prescriptionIdAccess,
                privateKey: privateKey,
                itemsQty: itemsQty,
                previewUrl: prescription.previewFile ? prescription.previewFile.url : null,
              };
              dispatch(addPrescriptionToOrder(prescriptionObject));
              navigate('/store/cart');
            }
          });
      }
    }
  }, [guestDepsReady, privateKey, cacheItems, dispatch, navigate]);

  // Adjusted useEffect to prevent multiple triggers
  useEffect(() => {
    if (performRequote) {
      RequoteService.requoteOrder(
        {
          loggedInPatient,
          authCookie,
          cacheItems,
          orderItems,
          selectedQuote,
          selectedAddress,
          addresses,
          dispatch,
          isRequoteTriggeredByCartChange: false,
          isRequoteTriggeredByLocationChange: true,
        },
        navigate
      ).then(() => {
        dispatch(setPerformRequote(false));
      });
    }
  }, [performRequote, dispatch, loggedInPatient, authCookie, navigate, cacheItems, orderItems, selectedQuote, selectedAddress, addresses]);

  return (
    <PageWrapper selectedMenuOption="cart" orderItemsCount={orderItems.length}>
      <ShoppingCartListContainer>
        <ShoppingCartList />
      </ShoppingCartListContainer>
      <PurchaseInfoContainer>
        <PurchaseInfo />
        <div className="d-flex flex-row justify-content-center">
          <Button
            style={{
              borderRadius: '0.8em',
              width: '12em',
              marginBottom: '3em',
              marginTop: '0em!important',
              backgroundColor:
                selectedAddress === null ||
                selectedPaymentMethod === null ||
                selectedQuote === null
                  ? '#4b3834'
                  : '#fdbf20',
              color:
                selectedAddress === null ||
                selectedPaymentMethod === null ||
                selectedQuote === null
                  ? '#FFFFFF'
                  : '#401409',
            }}
            variant="success"
            onClick={() => confirmOrder()}
          >
            Confirmar pedido...
          </Button>
        </div>
      </PurchaseInfoContainer>
    </PageWrapper>
  );
};

const ShoppingCartListContainer = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const PurchaseInfoContainer = styled.div`
  bottom: 0;
  left: 0;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
`;

export default CartView;