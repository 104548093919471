import { Breadcrumb, Container } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { 
  BrowserRouter as Router, 
  Routes,
  Route,
  Navigate,
  useNavigate,
  BrowserRouter
} from 'react-router-dom';
import "reflect-metadata";
import 'moment/locale/es-us';
import MainPrescriptionView from './views/erx/MainPrescriptionView';
import DrugSearchView from './views/erx/DrugSearchView';
import AddDrugView from './views/erx/AddDrugView';
import Login from './views/erx/Login';
import Validate from './views/erx/Validate';
import Unauthorized from './views/erx/Unauthorized';
import { RequireAuth } from './components/shared/RequireAuth';
import PrescriptionReview from './views/erx/PrescriptionReview';
import PrescriptionDetail from './views/erx/PrescriptionDetail';
import MonographView from './views/erx/MonographView';
import RegisterPatient from './views/erx/RegisterPatient';
import ValidateEncounter from './views/erx/ValidateEncounter';
import CartView from './views/store/CartView';
import DrugSearch from './views/store/DrugSearch';
import Addresses from './views/store/Addresses';
import HistoryView from './views/store/HistoryView';
import AddAddressForm from './components/store/addresses/AddAddressForm';
import AddPaymentMethodForm from './components/store/payment/AddPaymentMethodForm';
import PaymentMethodsList from './components/store/payment/PaymentMethodsList';
import QuotesList from './components/store/cart/QuotesList';
import { useDispatch, useSelector } from 'react-redux';
import { setLoggedInPatient } from './reducers/StoreSlice';
import { AuthService } from './services/drsbee/auth/AuthService';
import { Utilities } from './utility/Utilities';
import Checkout from './components/store/payment/Checkout';
import { useAuth } from './context/AuthContext';
import { PatientService } from './services/drsbee/data/PatientService';
import { NativeUtilities } from './utility/NativeUtilities';
import StorePreloader from './components/store/StorePreloader';
import DrugPresentationPicker from './components/store/cart/DrugPresentationPicker';
import PrescriptionPreviewModal from './components/erx/PrescriptionPreviewModal';
import LoginView from './views/store/LoginView';
import OrderStatusView from './views/store/OrderStatusView';
import StorePreRouter from './components/shared/StorePreRouter';
import CompleteGoogleRegisterView from './views/store/CompleteGoogleRegisterView';
function App() {
  const authService = new AuthService();
  const patientService = new PatientService();
  const util = new Utilities();
  const nativeUtil = new NativeUtilities();
  const dispatch = useDispatch();
  const { authCookie, setAuthCookie } = useAuth();
  // const navigate = useNavigate();
  useEffect(()=>{
    if(window.location.pathname.indexOf("store")!==-1 && nativeUtil.getPref("authHash")!==null){
      nativeUtil.getGeoLocation();
      authService.loginAsPatient(nativeUtil.getPref("authHash")).then(authResponse => {
        if(authResponse.successful && authResponse.value!==null){
          authService.checkSession().then(sessionResponse => {
            if(sessionResponse.successful && sessionResponse.value!==null){
              let cookie = util.extractCookie(sessionResponse.value);              
              setAuthCookie(cookie);
              patientService.getPatientInfo(cookie).then(patientResponse => {
                if(patientResponse.successful && patientResponse.value!==null){
                  dispatch(setLoggedInPatient(patientResponse.value));
                }     
              });
            }
          });
        }else{
          setAuthCookie(null);
        }
      });
    }
  },[]);

  return (
      <Container fluid style={{ background: '#ffffff', minHeight: '100%', paddingRight: '0', paddingLeft: '0' }}>
        <StorePreloader />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<StorePreRouter />}/>            
            <Route path="/rx/add_drug" element={<RequireAuth><DrugSearchView/></RequireAuth>} />
            <Route path="/rx/append/:id" element={<RequireAuth><AddDrugView edit={false}/></RequireAuth>} />
            <Route path="/rx/edit/:index" element={<RequireAuth><AddDrugView edit={true}/></RequireAuth>} />
            <Route path="/rx/review" element={<RequireAuth><PrescriptionReview/></RequireAuth>} />
            <Route path="/drug/monograph/:hash" element={<RequireAuth><MonographView/></RequireAuth>} />
            <Route path="/rx/prescription/preview" element={<RequireAuth><PrescriptionDetail /></RequireAuth>} />
            <Route path="/rx/prescription/erx_preview" element={<RequireAuth><PrescriptionPreviewModal /></RequireAuth> } />
            <Route path="/usr/patient/register" element={<RequireAuth><RegisterPatient/></RequireAuth>} />
            <Route path="/usr/validate/:hash" element={<Validate/>} />
            <Route path="/start/encounter/:uuid/:hash" element={<RequireAuth><ValidateEncounter/></RequireAuth>}/>
            <Route path="/session/validate" element={<Validate />} />
            <Route path="/store/login" element={<LoginView />} />
            <Route path="/store/search" element={<DrugSearch />} />
            <Route path="/store/cart/addresses" element={<Addresses />} />
            <Route path="/store/cart/addresses/add" element={<AddAddressForm />} />        
            <Route path="/store/cart" element={<CartView />} />
            <Route path="/store/cart/:privateKey" element={<CartView />} />
            <Route path="/store/cart/payment_methods" element={<PaymentMethodsList />} />
            <Route path="/store/cart/payment_methods/add" element={<AddPaymentMethodForm isGuest={false} />} />
            <Route path="/store/cart/payment_methods/pay" element={<AddPaymentMethodForm isGuest={true} />} />
            <Route path="/store/cart/quotes" element={<QuotesList />} />
            <Route path="/store/cart/cache/quotes" element={<QuotesList isCache={true}/>} />
            <Route path="/store/cart/checkout" element={<Checkout />} />
            <Route path="/store/cart/add/:id" element={<DrugPresentationPicker />} />
            <Route path="/store/order/history" element={<HistoryView />} /> 
            <Route path='/store/order/status' element={<OrderStatusView />}/>
            <Route path="/complete-google-register" element={<CompleteGoogleRegisterView />} />
            <Route path="/signin" element={<Login />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </BrowserRouter>
      </Container>
  );
}


export default App;
