import React, { Component } from "react";
import styled, { css } from "styled-components";

function AppLauncher(props) {
  return (
    <Container {...props}>
      <ScreenShot20230516At072647
        src={require("../../../assets/images/bee.png")}
      ></ScreenShot20230516At072647>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ScreenShot20230516At072647 = styled.img`
  height: 2.5em;
  width: 2.5em;
  background-color: transparent;
  object-fit: cover;
`;

export default AppLauncher;
