import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { AuthService } from "../../services/drsbee/auth/AuthService";  
import { PatientService } from "../../services/drsbee/data/PatientService";
import { useDispatch } from "react-redux";
import { useAuth } from "../../context/AuthContext";
import { setLoggedInPatient, resetState, performLogout } from '../../reducers/StoreSlice';
import { NativeUtilities } from "../../utility/NativeUtilities";
import Swal from 'sweetalert2';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';

const authService = new AuthService();
const patientService = new PatientService();
const nativeUtil = new NativeUtilities();

// Keyframes for spinner animation
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoginView = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { setAuthCookie } = useAuth();
    const navigate = useNavigate();

    const cleanSession = () => {
        window.localStorage.clear();
        window.sessionStorage.clear();        
        dispatch(performLogout());
        dispatch(resetState());
        nativeUtil.deleteAllCookies();   
        setAuthCookie(null);          
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        // Validate empty fields
        if (!email || !password) {
            Swal.fire({
                icon: 'warning',
                title: 'Campos Vacíos',
                text: 'Por favor, ingrese su correo electrónico y contraseña.',
            });
            return;
        }

        setIsLoading(true);

        const authHash = btoa(`${email}:${password}`);
        try {
            const response = await authService.loginAsPatient(authHash);
            console.log('Login response:', response);
            if (response.successful && response.value.userType === "PatientUser") {       
                // Clean current session before setting a new one
                cleanSession();

                // Get patient information
                const patientResponse = await patientService.getPatientInfo();
                if (patientResponse.successful && patientResponse.value!==null) {
                    dispatch(setLoggedInPatient(patientResponse.value));
                    window.localStorage.setItem("authHash", authHash);
                    navigate("/store/search");
                } else {
                    // Handle failure to get patient information
                    Swal.fire({
                        icon: 'warning',
                        title: 'Error',
                        text: 'No se pudo obtener la información del paciente.',
                    });
                }
            } else {
                // Show warning if login is unsuccessful
                Swal.fire({
                    icon: 'warning',
                    title: 'Inicio de Sesión Fallido',
                    text: 'Correo electrónico o contraseña inválidos.',
                });
            }
        } catch (error) {
            console.error("Login failed:", error);
            if (error.message === 'Unauthorized') {
                // Show specific warning for invalid credentials
                Swal.fire({
                    icon: 'warning',
                    title: 'Inicio de Sesión Fallido',
                    text: 'Correo electrónico o contraseña inválidos.',
                });
            } else {
                // Show generic warning for other errors
                Swal.fire({
                    icon: 'warning',
                    title: 'Error',
                    text: 'Hubo un problema al intentar iniciar sesión. Por favor, inténtelo de nuevo.',
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleGoogleSignIn = async (credentialResponse) => {
        setIsLoading(true);

        try {
            const { credential } = credentialResponse;
            if (credential) {
                console.log('Google Sign-In credential:', credential);
                const decoded = jwt_decode(credential);
                console.log(decoded);
                // Send the credential (ID token) to your backend
                const response = await authService.loginWithGoogle(credential);
                console.log('Google Sign-In response:', response);
                if (response.successful && response.value.loginSuccess && response.value.loginSuccess.userType === "PatientUser") {
                    // Clean current session before setting a new one
                    cleanSession();

                    // Get patient information
                    const patientResponse = await patientService.getPatientInfo();
                    if (patientResponse.successful && patientResponse.value!==null) {
                        dispatch(setLoggedInPatient(patientResponse.value));
                        navigate("/store/search");
                    } else {
                        Swal.fire({
                            icon: 'warning',
                            title: 'Error',
                            text: 'No se pudo obtener la información del paciente.',
                        });
                    }
                } else if (response.successful && response.value.googleSignInRequest) {
                    // User needs to complete registration
                    // Navigate to CompleteGoogleRegisterView and pass googleSignInRequest
                    navigate("/complete-google-register", { state: { googleSignInRequest: response.value.googleSignInRequest } });
                } else {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Inicio de Sesión Fallido',
                        text: 'No se pudo iniciar sesión con Google.',
                    });
                }
            }
        } catch (error) {
            console.error('Google Sign-In failed:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Hubo un problema al iniciar sesión con Google.',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleGuestCheckout = () => {
        cleanSession();
        navigate("/store/search");
    };

    return (
        <Container>
            <CenteredCard>
                <Logo src={require("../../assets/images/bee.png")} alt="DrsBee Logo" />
                <Title>Bienvenido a la tienda virtual de Drsbee</Title>
                <Subtitle>Por favor, inicie sesión para continuar</Subtitle>
                <FormWrapper>
                    <StyledForm onSubmit={handleLogin}>
                        <InputGroup>
                            <StyledInput
                                type="email"
                                placeholder="Correo Electrónico"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <StyledInput
                                type="password"
                                placeholder="Contraseña"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </InputGroup>
                        <ButtonGroup>
                            <StyledButton type="submit" disabled={isLoading}>
                                {isLoading ? (
                                    <>
                                        <Spinner />
                                        Iniciando Sesión...
                                    </>
                                ) : (
                                    "Iniciar Sesión"
                                )}
                            </StyledButton>
                            <ForgotPassword href="https://dev.drsbee.com/es-CR/Account/Login?patient=true">
                                ¿Olvidaste tu contraseña?
                            </ForgotPassword>
                            <RegistrationLink>
                                ¿No tienes una cuenta? <StyledTextLink href="https://dev.drsbee.com/es-CR/PatientUser/UserRegister" target="_blank" rel="noopener noreferrer">Regístrate aquí</StyledTextLink>
                            </RegistrationLink>
                            <p style={{color: '#401409'}}>También puedes:</p>
                            <GoogleLogin
                                width="600px"
                                onSuccess={credentialResponse => {
                                    handleGoogleSignIn(credentialResponse);
                                }}
                                onError={() => {
                                    console.log('Login Failed');
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Error',
                                        text: 'El inicio de sesión con Google ha fallado.',
                                    });
                                }}
                                useOneTap
                            />
                            
                        </ButtonGroup>
                    </StyledForm>
                </FormWrapper>
                
                <Separator>O</Separator>
                
                <ButtonGroup>
                    <GuestButton onClick={handleGuestCheckout}>
                        Comprar como Invitado
                    </GuestButton>
                </ButtonGroup>
            </CenteredCard>
        </Container>
    );
};

// Styled Components

const Container = styled.div`
    display: flex;
    background-color: #f5f5f5;
    flex-direction: column;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    padding: 20px;
`;

const CenteredCard = styled.div`
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-sizing: border-box;
    width: 100%;
    max-width: 600px; /* Incrementar el ancho en desktop */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    align-items: center;

    @media (max-width: 480px) {
        padding: 20px;
        max-width: 90%;
    }
`;

const Logo = styled.img`
    display: block;
    margin: 0 auto 20px auto;
    width: 80px;
    height: 80px;

    @media (max-width: 480px) {
        width: 60px;
        height: 60px;
    }
`;

const Title = styled.h2`
    text-align: center;
    color: #fdbf20;
    margin-bottom: 10px;
    font-size: 24px;

    @media (max-width: 480px) {
        font-size: 20px;
    }
`;

const Subtitle = styled.h5`
    text-align: center;
    color: #401409;
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 16px;

    @media (max-width: 480px) {
        font-size: 14px;
    }
`;

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const StyledForm = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;

    @media (min-width: 480px) {
        flex-direction: column;
    }
`;

const StyledInput = styled.input`
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 2px solid #fdbf20;
    border-radius: 4px;
    font-size: 16px;

    &:focus {
        border-color: #e0a800;
        outline: none;
    }

    @media (max-width: 480px) {
        padding: 10px 16px;
        font-size: 14px;
    }
`;

const ButtonGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @media (min-width: 480px) {
        flex-direction: column;
    }
`;

const StyledButton = styled.button`
    background-color: #fdbf20;
    color: #401409;
    padding: 12px 20px;
    margin-bottom: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #e0a800;
    }

    &:disabled {
        background-color: #e0a800;
        cursor: not-allowed;
    }

    @media (max-width: 480px) {
        padding: 10px 16px;
        font-size: 14px;
    }
`;

const GoogleButton = styled(StyledButton)`
    background-color: white;
    color: #555;
    border: 2px solid #fdbf20;

    &:hover {
        background-color: #f5f5f5;
    }
`;

const GuestButton = styled(StyledButton)`
    background-color: #fdbf20;
    color: #401409;
    text-decoration: none;

    &:hover {
        background-color: #e0a800;
    }
`;

const Spinner = styled.div`
    border: 4px solid #f3f3f3;
    border-top: 4px solid #e0a800;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: ${spin} 2s linear infinite;
    margin-right: 10px;

    @media (max-width: 480px) {
        width: 16px;
        height: 16px;
        border-width: 3px;
    }
`;

const Separator = styled.div`
    text-align: center;
    margin: 20px 0;
    position: relative;
    font-size: 14px;
    color: #555;

    &:before, &:after {
        content: '';
        position: absolute;
        top: 50%;
        width: 40%;
        height: 1px;
        background-color: #ccc;
    }

    &:before {
        left: 0;
    }

    &:after {
        right: 0;
    }

    @media (max-width: 480px) {
        font-size: 12px;
    }
`;

const ForgotPassword = styled.a`
    color: #401409;
    margin-bottom: 5px;
    text-align: center;
    display: block;
    font-size: 14px;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    @media (max-width: 480px) {
        font-size: 12px;
    }
`;

const RegistrationLink = styled.div`
    text-align: center;
    margin-top: 5px;
    margin-bottom: 30px;
    font-size: 14px;
    color: #555;

    @media (max-width: 480px) {
        font-size: 12px;
    }
`;

const StyledTextLink = styled.a`
    color: #fdbf20;
    text-decoration: none;
    font-weight: 500;

    &:hover {
        text-decoration: underline;
    }
`;

export default LoginView;
