import React, { createContext, useContext, useState } from 'react';

// Create the AuthContext
const AuthContext = createContext();

// Create a custom AuthProvider component
export const AuthProvider = ({ children }) => {
  const [authCookie, setAuthCookie] = useState(window.localStorage.getItem('JSESSIONID'));

  // Wrap authCookie and setAuthCookie in an object
  const authContextValue = {
    authCookie,
    setAuthCookie,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

// Create a custom hook to use the AuthContext
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
