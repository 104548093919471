import React from "react";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaExclamationCircle } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { changeSelectedQuote, setPerformRequote } from '../../../reducers/StoreSlice';
import { useNavigate } from "react-router-dom";

const OrderQuote = ({ quote, colorCode, complete }) => {
    const dispatch = useDispatch();
    const selectedQuote = useSelector((state) => state.store.selectedQuote);
    const navigate = useNavigate();

    const handleSelectQuote = () => {
        dispatch(changeSelectedQuote(quote));
        dispatch(setPerformRequote(false));
        navigate('/store/cart/');
    };

    const isSelected = selectedQuote && quote.pharmacyId === selectedQuote.pharmacyId;

    return (
        <Card 
            style={{ flex: '0 0 auto', width: '16em', marginLeft: '1em', marginRight: '1em' }}             
        >
            <Card.Header style={{ display: 'flex', alignItems: 'center', borderTop: `6px solid ${colorCode}` }} onClick={handleSelectQuote}>
                <input
                    type="radio"
                    checked={isSelected}
                    onChange={handleSelectQuote}
                    style={{ marginRight: '0.5em' }}
                />
                {quote.pharmacyDescription}
            </Card.Header>
            <Card.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={handleSelectQuote}>
                <img 
                    style={{ height: '6em' }} 
                    src={quote.pharmaceuticalGroupLogo?.url || ""} 
                    alt="Logo" 
                />
            </Card.Body>
            <Card.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {!complete && (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                Esta cotización no cubre la totalidad de productos de tu carrito
                            </Tooltip>
                        }
                    >
                        {({ ref, ...triggerHandler }) => (
                            <span ref={ref} {...triggerHandler}>
                                <FaExclamationCircle 
                                    style={{
                                        color: '#fbba5a',
                                        width: '1.6em',
                                        height: '1.6em',
                                        marginRight: '0.5em',
                                        marginBottom: '0.5em',
                                    }}
                                />
                            </span>
                        )}
                    </OverlayTrigger>
                )}
                <h3>{quote.totalPriceStr}</h3>
            </Card.Footer>
        </Card>
    );
};

export default OrderQuote;
