import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AuthService } from '../../services/drsbee/auth/AuthService';
export function RequireAuth({ children }: { children: JSX.Element }) {
    const {hash} = useParams();
    const {uuid} = useParams();
    let location = useLocation();
    const patient = useSelector((state) => state.prescription.patient);
    if({hash}.hash!==null && window.localStorage.getItem('br_token')===null) window.localStorage.setItem('br_token', {hash}.hash);
    if({uuid}.uuid!==null && location.pathname.indexOf('start/encounter')!==-1) window.localStorage.setItem('patientUUID', {uuid}.uuid);
  
    const authService = new AuthService();
    let physician = JSON.parse(window.localStorage.getItem('physician'));
    let authorization = window.localStorage.getItem('authorization');
    let beeToken = window.localStorage.getItem('br_token');
    
    if (physician===null && document.cookie==="" && authorization===null) {
      if(beeToken!==null) {        
        return <Navigate to={`/usr/validate/${beeToken}`} state={{ from: location }} replace />
      }
      else return <Navigate to="/store/login" state={{ from: location }} replace />;
    } else if(physician!==null && document.cookie!=="" && authorization!==null){
      if(window.localStorage.getItem("patientUUID")!==null){
        let patientUUID = window.localStorage.getItem("patientUUID");
        return <Navigate to={`/start/encounter/${patientUUID}/${beeToken}`} state={{ from: location }} replace />
      
      }
      return children;
    }else {
      return children;
    }
  }
