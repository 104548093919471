import React from 'react';
import DrugImg from '../../../assets/dummy/vial.png';
import HiveCheckIcon from '../../../assets/dummy/hive-check.png';
import { IoLocationSharp } from 'react-icons/io5';
import Fischel from '../../../assets/logos/fischel.svg';
import CustomButton from '../../shared/CustomButton';
import { FaPlus, FaVial } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentDrug } from '../../../reducers/RxSlice';

/**
 * SearchResults
 * @param medicines Array of medicine items
 * @returns {JSX.Element}
 * @constructor
 */
interface Medicine {
  id: number;
  name: string;
  content: string;
  quantity: string;
  image?: string;
  monograph?: string;
  available: number;
  doseUnitDescription: string;
  logo?: string;
}

interface CartSearchResultProps {
  medicines: Medicine[];
}

const CartSearchResults = ({ medicines }: CartSearchResultProps) => {
  const lastResults = useSelector((state) => state.prescription.lastResults);
  const dispatch = useDispatch();
  const selectDrug = (id: string) => {
    for (var x = 0; x < lastResults.length; x++) {
      if (lastResults[x].id.toString() === id) {
        dispatch(setCurrentDrug(lastResults[x]));
      }
    }
  };

  const _getMedicineInfo = (medicine: Medicine, index) => {
    return (
      <div
        className="d-flex flex-row py-2 align-items-center justify-content-between mx-3 px-2"
        style={{ borderTop: '1px solid #CCCCCC', opacity: 1}} key={index}
      >
        <div
          className="d-flex flex-column"
          style={{
            flex: '2 0 0%',
            fontSize: '8pt',
            marginTop: '0.7em',
            lineHeight: '0.7rem',
            color: 'var(--unlisted-black)',
          }}
        >
          <FaVial style={{ color: '#401409', height: 30, width: 30, marginBottom: '-2.4em', marginRight: '2em'}} />          
          <span className="bold" style={{fontSize: '11pt',textAlign: 'left', marginLeft: '3em', marginBottom: '1.5em', alignItems: 'center', lineHeight: '1em'}}>{medicine.name}</span>
        </div>
        <div
          className="d-flex flex-row justify-content-between align-items-center"
          style={{ flex: 0 }}
        >
          <Link to={`/store/cart/add/${medicine.id}`}>
            <CustomButton
              type="icon"
              color="primary"
              disabled={false}
              action={() => dispatch(setCurrentDrug(medicine))}
              icon={<FaPlus color="#401409" style={{ marginBottom: 4, height: 11 }} />}
            />
          </Link>
        </div>
      </div>
    );
  };

  return <>{medicines.map((medicine, index) => _getMedicineInfo(medicine, index))}</>;
};

export default CartSearchResults;
