import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import CustomModal from '../shared/CustomModal';
const PrescriptionPreviewModal = () => {
  const [show, setShow] = useState(true);
  const navigate = useNavigate();
  const prescriptionSummary = useSelector((state) => state.prescription.prescriptionSummary);  
  const handleClose = () => {
    navigate(-1);
  }
  const _getHeaderContent = () => (
    <>
    </>
  );
  const _getBodyContent = () => (
    <>
    <div>
        <img src={prescriptionSummary.url} style={{width: '100%'}}/>
    </div>
    
    </>
  );
  return (
    <Container style={{ paddingRight: 30, paddingLeft: 30, textAlign: "center"}} className='justify-content-center'>
    <CustomModal
        title='Previsualizar eRx'
        headerContent={_getHeaderContent()}
        bodyContent={_getBodyContent()}
        handleClose={handleClose}
        show={show}
        fullBleed
    />
</Container>

  );
};


export default PrescriptionPreviewModal;
