import React, {useState} from 'react';
import { Container, createTheme  } from "@mui/material";
import { Row } from "react-bootstrap";
import { LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";
import { ThemeProvider } from "@emotion/react";
import CustomTextField from "../../components/shared/CustomTextField";
import { useSelector } from 'react-redux';
import { PrescriptionService } from '../../services/drsbee/data/PrescriptionService';
import { PatientService } from '../../services/drsbee/data/PatientService';
import { Utilities } from '../../utility/Utilities';
import Divider from "../../components/shared/Divider";
import CustomButton from '../../components/shared/CustomButton';
import PrescriptionPreviewModal from '../../components/erx/PrescriptionPreviewModal';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
const theme = createTheme({
    palette: {
        primary: {
            main: '#5CA2A0',
        },
    },
    components: {
        MuiPickerStaticWrapper: {
            styleOverrides: {
                root: {
                    position: 'relative',
                    margin: '0 auto',
                    maxWidth: '320px'
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    marginBottom: '20px'
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    width: '100%'
                }
            }
        }
    },
});

const PrescriptionReview = () => {
    const prescriptionService = new PrescriptionService();
    const patientService = new PatientService();
    const prescriptionSummary = useSelector((state) => state.prescription.prescriptionSummary);
    const patientProfile = useSelector((state) => state.prescription.patientProfile);
    const encounter = useSelector((state) => state.prescription.encounter);
    const prescriptionVM = useSelector((state) => state.prescription.prescriptionVM);
    const physicianBillingSettings = JSON.parse(window.localStorage.getItem("physicianBillingSettings"));
    const util = new Utilities();
    var email = patientProfile.email;
    var phoneNumber = patientProfile.phoneNumber;
    var consultCost = physicianBillingSettings.consultCost.value;
    var prescriptionCost = physicianBillingSettings.prescriptionCost.value;
    const finishEncounter = (email = null, phoneNumber = null) => {
        let profile = patientProfile.hasOwnProperty("address") ? patientService.cleanPatientProfile(patientProfile) : patientProfile;
        var encounterVM = prescriptionService.buildEncounterViewModel(encounter,profile,prescriptionService.buildPrescriptionViewModel(prescriptionVM));
        prescriptionService.finishEncounter(prescriptionService.buildFinishViewModel(encounterVM,email,phoneNumber)).then(res => {
            Swal.fire({
                title: 'Receta creada correctamente',
                text: 'Se ha enviado una notificación al paciente',
                icon: 'success',
                confirmButtonText: 'Volver a Hive'
            }).then((result)=>{
               util.closeWebView();
            });
            
        });
    }
    return(
        <Container>
            <LocalizationProvider dateAdapter={DateAdapter}>
                <ThemeProvider theme={theme}>
                    <Row className="pb-3 justify-content-center" style={{ background: 'var(--white)' }}>
                        <div className="d-flex align-items-center justify-content-center px-1 my-1">
                            <span className='subtitle align-items-center'>Finalizar Atención</span>
                        </div>
                        <Divider/>
                        <div className="d-flex align-items-center justify-content-center px-1 my-1">
                            <b>Paciente:</b> {patientProfile.firstName + " " + patientProfile.lastName}
                        </div>
                        <div className="d-flex align-items-center justify-content-center px-1 my-1">
                            <b>Identificación:</b> {patientProfile.identification}
                        </div>
                        <div className="d-flex align-items-center justify-content-center px-1 my-1">
                            <b>Fecha:</b> {encounter.date}
                        </div>
                        <div className="d-flex align-items-center justify-content-between px-4 my-3">
                            <CustomTextField id="email" label="Email" value={patientProfile.email} onChange={(val) => email=val}/>
                        </div>
                        <div className="d-flex align-items-center justify-content-between px-4 my-3">
                            <CustomTextField id="phoneNumber" label="Teléfono" value={patientProfile.phoneNumber} onChange={(val) => phoneNumber=val} />
                        </div>
                        <div className="d-flex align-items-center justify-content-between px-4 my-3">
                            <CustomTextField label="Costo de la consulta (₡)" value={consultCost} onChange={(val) => prescriptionCost=val} />
                            <CustomTextField label="Costo de la prescripción (₡)" value={prescriptionCost} onChange={(val) => prescriptionCost=val} />
                        </div>
                    </Row>
                </ThemeProvider>
            </LocalizationProvider>
            <div className="d-flex fixed-bottom flex-row justify-content-between p-2" style={{ background: 'var(--unlisted-teal )' }}>
            <Link to="/rx/prescription/erx_preview">
                <CustomButton
                    type='text'
                    color='primary'
                    buttonStyle={{ width: '11em', height: '2.5em' }}
                    textStyle={{ color: 'var(--black)', fontWeight: 700 }}                    
                    text='Mostrar Prescripción'
                />
            </Link>
            <CustomButton
                type='text'
                color='primary'
                buttonStyle={{ width: '11em', height: '2.5em' }}
                textStyle={{ color: 'var(--black)', fontWeight: 700 }}
                action={() => finishEncounter(email, phoneNumber)}
                text='Finalizar Consulta'
            />                
            </div>
        </Container>
    )
}

export default PrescriptionReview;