import React, { useState } from 'react';
import ColegioCostaRica from '../../assets/logos/colegiomedicos.png';
import { AuthService } from '../../services/drsbee/auth/AuthService';
import { PharmacyService } from '../../services/drsbee/data/PharmacyService';
import { createTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import {
  setAuthorizationToken,
  setPharmacyList,
  setPhysician,
  setPhysicianBillingSettings
} from '../../reducers/RxSlice';
import { Container, Row } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import Swal from 'sweetalert2';
import CustomButton from '../../components/shared/CustomButton';
import { Utilities } from '../../utility/Utilities';
import LoadingSpinner from '../../components/shared/LoadingSpinner';

const theme = createTheme({
    palette: {
        primary: {
        main: '#5CA2A0'
        }
    }
});

const Login = () => {
    const dispatch = useDispatch();
    const util = new Utilities();
    const authService = new AuthService();
    const pharmacyService = new PharmacyService();

    const [loggedIn, setLoggedIn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [physicianUser, setPhysicianUser] = useState(
        JSON.parse(window.localStorage.getItem('physician'))
    );
    const [showError, setShowError] = useState(false);

    const raiseError = () => {
        Swal.fire({
        title: 'Invalid credentials',
        text: 'Please check your username/password, and try again',
        icon: '',
        confirmButtonText: 'Entendido'
        }).then((result) => {
        setShowError(true);
        });
    };

    const validateUser = (usr, pwd) => {
        const authHash = btoa(usr + ':' + pwd);
        setLoading(true);

        if (physicianUser == null) {
        authService
            .loginAsPhysician(authHash)
            .then((res) => {
            if (res.successful) {
                dispatch(setAuthorizationToken(authHash));
                setLoggedIn(true);

                authService.getLoggedInPhysician().then((resp) => {
                const physician = authService.buildPhysicianVM(resp.value.physicianProfile);
                dispatch(setPhysician(physician));
                dispatch(setPhysicianBillingSettings(resp.value.userSettings.currencySettingByName));
                setPhysicianUser(JSON.parse(window.localStorage.getItem('physician')));

                pharmacyService
                    .findPharmaciesByLocation(
                    physician.doctorOffices[0].locationLatitude,
                    physician.doctorOffices[0].locationLongitude
                    )
                    .then((pharmacyResult) => {
                    if (pharmacyResult.successful) {
                        dispatch(setPharmacyList(pharmacyService.getPharmaciesByGroup(pharmacyResult.value)));
                        setLoading(false);
                    }
                    });

                authService.checkSession().then((data) => {
                    const cookie = data.value;
                    if (cookie.indexOf('NOUSER') !== -1) {
                    authService.destroySession();
                    setLoggedIn(false);
                    } else {
                    const authorization = util.extractCookie(cookie);
                    document.cookie = authorization;
                    window.localStorage.setItem('authorization', authorization);
                    setLoggedIn(true);
                    }
                });
                });
            } else {
                raiseError();
                setLoggedIn(false);
            }
            })
            .catch((err) => {
            setLoading(false);
            console.log(err);
            raiseError();
            setLoggedIn(false);
            });
        } else if (physicianUser != null) {
        setLoggedIn(true);
        } else {
        setLoggedIn(false);
        }
    };

    return (
        <Container>
        <Row className='justify-content-center p-5'>
            <img src={ColegioCostaRica} style={{ width: '15em' }} alt='Logo' />
            <ThemeProvider theme={theme}>
            <Row className='pb-3 justify-content-center' style={{ background: 'var(--white)' }}>
                <div className='d-flex align-items-center justify-content-center px-4 my-3'>
                    <input type='email' className='form-control' placeholder='Email' id='email' style={{ width: '20em' }} />
                </div>
                <div className='d-flex align-items-center justify-content-center px-4 my-3'>
                    <input type='password' className='form-control' placeholder='Password' id='password' style={{ width: '20em' }} />
                </div>
                {loading ? (
                <div className='d-flex align-items-center justify-content-center my-3'>
                    <LoadingSpinner />
                    <span className='mx-1'>Iniciando sesión</span>
                </div>
                ) : null}
                <CustomButton
                    type='text'
                    color='primary'
                    buttonStyle={{ width: '12em', height: '2.5em' }}
                    textStyle={{ color: 'var(--black)', fontWeight: 700 }}
                    action={() => validateUser(document.getElementById('email').value, document.getElementById('password').value)}
                    text='Iniciar sesión'/>
                {physicianUser != null ? <Navigate to='/' replace /> : null}
            </Row>
            </ThemeProvider>
        </Row>
        </Container>
    );
};

export default Login;
