import { Container, Row } from "react-bootstrap";
import { Button, createTheme, TextField } from "@mui/material";
import ColegioMedicos from '../../assets/logos/colegiomedicos.png'
import "./Unauthorized.css";
const theme = createTheme({
    palette: {
        primary: {
            main: '#5CA2A0',
        },
    }
});
const Unauthorized = () => {
    return (<Container>
        <Row className="p-5 m-5 justify-content-center">
            <img src={ColegioMedicos} style={{width: '15em'}} /> 
            <p>You're not authorized to access this resource</p>
        </Row>
    </Container>);
};
export default Unauthorized;