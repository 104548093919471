import React, { useMemo } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { useParams, useNavigate } from 'react-router-dom';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import CustomModal from '../../components/shared/CustomModal';

const MonographView = () => {
    const navigate = useNavigate();
    const defaultLayoutPluginInstance = useMemo(() => defaultLayoutPlugin(), []);
    const { hash } = useParams();
    const pdfUrl = require(`../../assets/vademecum/pdf/${atob(hash)}`);
    const handleClose = () => navigate(-1);

    const _getHeaderContent = () => (
        <div className='d-flex flex-row pt-2'>
            <span style={{ fontSize: 14, color: 'var(--unlisted-black)', flex: 0.95 }}>
            </span>
        </div>
    );

    const _getBodyContent = () => (
        <div className='px-2 pt-2' style={{ fontSize: 12, color: 'var(--unlisted-black)', paddingBottom: 20 }}>
        </div>
    );

    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.7.570/es5/build/pdf.worker.js">
            <div style={{ height: '100%' }}>
                <CustomModal
                    title='Monografía del medicamento'
                    headerContent={_getHeaderContent()}
                    bodyContent={
                        <>
                            {_getBodyContent()}
                            <div className='d-flex flex-column'>
                                <Viewer fileUrl={pdfUrl} plugins={[defaultLayoutPluginInstance]} />
                            </div>
                        </>
                    }
                    handleClose={handleClose}
                    show={true}
                    fullBleed={false}
                />
            </div>
        </Worker>
    );
};

export default MonographView;
