import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setSearchPlaceholder } from "../../reducers/RxSlice";
import CartSearchResults from "../../components/store/cart/CartSearchResults";
import CustomSearchInput from "../../components/shared/CustomSearchInput";
import { DrugService } from "../../services/drsbee/data/DrugService";
import { useAuth } from "../../context/AuthContext";
import PageWrapper from "../../components/shared/PageWrapper";
const drugService = new DrugService();

const DrugSearch = (props) => {
    const currentData = { medicines: [] };
    const dispatch = useDispatch();
    const [filter, setFilter] = useState(null);
    const [search, setSearch] = useState('');
    const [results, setResults] = useState(currentData.medicines);
    const authCookie = useAuth();
    const orderItems = useSelector((state) => state.store.orderItems);
    const loggedInPatient = useSelector((state) => state.store.loggedInPatient);
    useEffect(() => {
        dispatch(setSearchPlaceholder("Buscar medicamentos"));
    }, [dispatch]);

    const stageResults = (queryResults) => {
        let newList = [];
        queryResults.forEach(element => {
            let drugItem = {
                id: element.id,
                name: element.suggestedDescription,
                content: '',
                quantity: '',
                doseUnitDescription: element.doseUnitPluralDescription,
                image: null,
                monograph: null,
                available: (element.availability != null ? parseInt(element.availability) : 0),
                deletable: true,
                logo: null,
                unit: element.doseUnitPluralDescription,
                type: '',
                drugObj: element
            }
            if (drugService.isDrugSponsored(element.description)) {
                let media = drugService.getSponsoredMedia(element.description);
                drugItem.image = media["image"];
                if (typeof (media["monograph"]) === 'object') {
                    drugItem.monograph = media["monograph"][JSON.stringify(element.monograph.activeIngredientById["211"].strengthA)];
                } else { drugItem.monograph = media["monograph"]; }
            }
            newList.push(drugItem);
        });
        setResults(newList);
    };

    const handleSearch = (val) => {
        if (val && val!=='') {
            if(loggedInPatient !== null){
                drugService.searchDrugs(val, "18", authCookie).then((response) => {
                    if (response.successful && response.value !== null) {
                        stageResults(response.value.drugsByName);
                    }
                });
            }else{
                drugService.searchDrugs(val,"18").then((response) => {
                    if(response.successful && response.value !== null){                   
                        stageResults(response.value.drugsByName);

                    }
                });
            }
        }
        else{
            stageResults([]);
        }
        setSearch(val);
    };

    return (
        <PageWrapper selectedMenuOption="search" orderItemsCount={orderItems.length}>
            <CustomSearchInput search={handleSearch} />
            <CartSearchResults medicines={results} />
        </PageWrapper>
    );
};

export default DrugSearch;
